module.exports = [
    {
        "title": "Bureautique",
        "slogan": "Apprenez à maitriser l'outils informatique",
        "items": [
            {
                "id": "540256cf-b629-45fd-a61c-c093870b63d6",
                "title": "Autocad",
                "image": "autocad_1.png",
                "pdfFile": "/formations/bureautique/autocad.pdf",
                "body": `
                    <div className='blog-main-content'>
                    <p>Grâce à cette formation vous pourrez effectuer des plans et dessins en 2D et 3D.</p>

                    <h4 className='title'>Vous pourrez par la suite :</h4>
                    <ul><li>Réaliser des dessins techniques</li>
                    <li>Réaliser des dessins en 2D et 3D</li></ul>

                    <h4 className='title'>Objectifs :</h4>
                    <ul><li>Maîtriser le dessin assisté et la conception assistée par ordinateur</li>
                    <li>Créer et modifier des dessins facilement et rapidement</li></ul>

                    <h4 className='title'>Certification :</h4>
                    <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I-formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre 2018, Décret n°2019-565 du 6 juin 2019).</p>

                    <h4 className='title'>Financement :</h4>
                    <p>Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>

                    <hr>

                    <div className="text-center rounded" className="thumbnail block-alignwide">
                    <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/autocad_2.png' alt="Autocad picture" />
                    </div>

                    <hr>

                    <h4>Programme de formation</h4>
                                    
                    <h6>Objectifs pédagogiques</h6>
                    <p>Avec cette formation AutoCAD, vous pourrez en 118 leçons maîtriser les fonctionnalités du logiciel AutoCad : des objets à la gestion des calques et de l'édition de plans à des applications pratiques adaptées aux métiers de l'industrie et du bâtiment.</p>

                    <h6>Système d'évaluation</h6>
                    <p>Questionnaires des acquis en amont puis en fin de la formation.</p>

                    <h6>Accessibilité</h6>
                    <p>Nos formations sont adaptées au public en situation de handicap.</p>

                    <h6>Pré requis technique</h6>
                    <ul>
                    <li>Navigateur web : Edge, Chrome, Firefow, Safari</li>
                    <li>Système d'exploitation : Mac ou PC, smartphone</li>
                    </ul>

                    <hr>

                    <h4>Détail formation</h4>
                    
                    <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg>  Interface et navigation 2D</h6></summary>
                    <ul>
                    <li>Interface de démarrage</li>
                    <li>Interface de démarrage et panneau « créer »</li>
                    <li>Interface de démarrage et connexion à A360</li>
                    <li>Compréhension de l'interface</li>
                    <li>Description de base de l'interface</li>
                    <li>Le menu d'application AutoCAD</li>
                    <li>La barre d'état</li>
                    <li>Personnaliser et enregistrer l'espace de travail</li>
                    <li>La ligne de commande</li>
                    <li>Réglages des couleurs de l'interface, police de la ligne de commande</li>
                    <li>Enregistrement automatique et copies de sauvegarde</li>
                    <li>Ouvrir un ou plusieurs plans DWG</li>
                    <li>Les onglets de fichiers</li>
                    <li>Le périphérique de pointage</li>
                    <li>La navigation 2D à la molette et à la souris</li>
                    <li>Paramétrage des variables</li>
                    </ul></details>

                    <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Fonctions de base en dessin 2D</h6></summary>
                    <ul>
                    <li>Le mode de saisie dynamique</li>
                    <li>La fonction « ligne » et le mode de saisie dynamique</li>
                    <li>La fonction « ligne », tracés verticaux et horizontaux </li>
                    <li>La fonction « ligne », repérage et accrochage </li>
                    <li>La fonction « ligne », les chemins polaires </li>
                    <li>La fonction « ligne », le mode ortho</li>
                    <li>La fonction « cercle »</li>
                    <li>La fonction « arc »</li>
                    <li>La fonction « rectangle »</li>
                    <li>La fonction « polygone »</li>
                    <li>La fonction « droite » </li>
                    <li>La fonction « demi-droite » </li>
                    <li>La fonction « droite », décalage </li>
                    <li>La fonction « polyligne» </li>
                    </ul></details>

                    <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Outils d'aide aux dessins et renseignements</h6></summary>
                    <ul>
                    <li>Les diverses parties d'un document</li>
                    <li>Marges, format et orientation</li>
                    <li>La règle horizontale</li>
                    <li>Mise en forme au niveau caractères</li>
                    <li>Mise en forme au niveau paragraphes</li>
                    <li>Révéler la mise en forme</li>
                    <li>Bien utiliser les tabulations</li>
                    <li>Bordures et trames</li>
                    <li>Arrière-plan du document</li>
                    <li>Filigranes</li>
                    <li>Lettrines</li>
                    <li>Modifier La casse des caractères</li>
                    <li>Puces et numérotation</li>
                    <li>Sections</li>
                    </ul></details>

                    <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Sélection d'objets et fonctions de base en modifications de plan</h6></summary>
                    <ul>
                    <li>Sélection directe - Effacer</li>
                    <li>Fenêtre de sélection et de capture - Effacer</li>
                    <li>Fenêtre de sélection et de capture - Réglages de base</li>
                    <li>Techniques de base en sélection</li>
                    <li>Sélection rapide</li>
                    <li>Utilisation des grips - Concepts</li>
                    <li>Editions de plans - Fonction « Effacer »</li>
                    <li>Editions de plans - Fonction « Déplacer »</li>
                    <li>Editions de plans - Fonction « Copier »</li>
                    <li>Editions de plans - Fonction « Miroir»</li>
                    <li>Editions de plans - Fonction « Rotation »</li>
                    <li>Editions de plans - Fonction « Décaler »</li>
                    <li>Editions de plans - Fonction « Echelle »</li>
                    <li>Editions de plans - Fonction « Ajuster »</li>
                    <li>Editions de plans - Fonction « Prolonger »</li>
                    <li>Editions de plans - Fonction « Etirer»</li>
                    <li>Editions de plans - Fonction « Raccord »</li>
                    <li>Editions de plans - Fonction « Chanfrein »</li>
                    </ul></details>

                    <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Gestion des calques et propriétés d'objets</h6></summary>
                    <ul>
                    <li>Concepts de base</li>
                    <li>États de calque</li>
                    <li>Création et propriétés du calque</li>
                    <li>Productivité</li>
                    <li>Gestion de base des propriétés</li>
                    <li>Type de ligne</li>
                    <li>Epaisseur de ligne</li>
                    </ul></details>

                    <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Commencer un plan DWG</h6></summary>
                    <ul>
                    <li>Enregistrer</li>
                    <li>Réglage de base</li>
                    <li>Structure de calques simple</li>
                    <li>Concept des « Présentations»</li>
                    <li>Mise en page nommée</li>
                    <li>Affectation des mises en page nommée</li>
                    <li>Modification des mises en page nommée et création d'un cadre</li>
                    <li>Création d'une fenêtre de présentation rectangulaire</li>
                    <li>Déplacer et dupliquer une présentation</li>
                    <li>Création d'un gabarit DWT</li>
                    <li>Créer un plan DWG en utilisant un gabarit DWT</li>
                    </ul></details>

                    <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Application métier industrie - Création du plan d'un composant</h6></summary>
                    <ul>
                    <li>Présentation du composant</li>
                    <li>Démarrage du plan</li>
                    <li>Tracés du contour principal</li>
                    <li>Tracés des rainures en T</li>
                    <li>Projections des arêtes</li>
                    <li>Création de hachures</li>
                    <li>Fenêtres de présentation et mise à l'échelle</li>
                    <li>Gestion des échelles de ligne</li>
                    <li>Flèche d'identification de coupe</li>
                    <li>Le style de texte annotatif</li>
                    <li>Création de texte multiligne annotatif</li>
                    <li>Création de cotes annotatives</li>
                    <li>Création de cotes linéaires annotatives</li>
                    <li>Compléments cotations</li>
                    <li>Cotation du détail - Méthode A</li>
                    <li>Cotation du détail - Méthode B</li>
                    <li>Style de ligne de repère multiple annotatif</li>
                    <li>Création de repère multiple annotatif</li>
                    <li>Imprimer un plan</li>
                    <li>Publier en PDF</li>
                    </ul></details>

                    <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Application métier bâtiment - Création d'un plan de rez-de-chaussée</h6></summary>
                    <ul>
                    <li>Présentation du plan simple d'un rez-de-chaussée</li>
                    <li>La structure des calques</li>
                    <li>Création des murs extérieurs</li>
                    <li>Positionnement des axes de baies, de portes ou de fenêtres</li>
                    <li>Technique de création de symboles simplifiés de portes</li>
                    <li>Création de blocs menuiseries - Présentation</li>
                    <li>Création d'un bloc « Interne »</li>
                    <li>Insertion et placement des blocs menuiseries</li>
                    <li>Mise au net du plan - Fonction contour</li>
                    <li>Présentation et échelle du plan</li>
                    <li>Cotations annotatives, le style « Archi »</li>
                    <li>Création de côtes linéaires annotatives extérieures et intérieures</li>
                    <li>Compléments - Gestion des objets annotatifs</li>
                    <li>Création de contours en polyligne de pièces</li>
                    <li>Annotations et calculs de surfaces de pièces</li>
                    <li>Gestion des échelles de types de ligne</li>
                    <li>Exports de présentation aux formats PDF et DWFx</li>
                    </ul></details></div>`
            },
            {
                "id": "c8008538-3be8-41bb-9dd7-cc7fde93e762",
                "title": "Excel",
                "image": "excel_1.png",
                "pdfFile": "/formations/bureautique/excel.pdf",
                "body": `
                    <div className='blog-main-content'>
                    <p>Perfectionner votre savoir-faire sur Excel vous permettra de multiplier votre productivité.</p>
                    
                    <h4 className='title'>Vous pourrez par la suite :</h4>
                    <ul><li>Créer des tableaux</li>
                    <li>Maîtriser les calculs</li>
                    <li>Maîtriser les fonctions statistiques et mathématiques</li></ul>
                    
                    <h4 className='title'>Objectifs :</h4>
                    <ul><li>Créer et gérer  des documents Excel</li>
                    <li>Mettre en forme un tableau et des données</li>
                    <li>Saisir des formules de calculs simples et créer des graphiques simples</li></ul>
                    
                    <h4 className='title'>Certification :</h4>
                    <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I-formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre 2018, Décret n° 2019-565 du 6 juin 2019).</p>
                    
                    <h4 className='title'>Financement :</h4>
                    <p>Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>
                    
                    <hr>
                    
                    <div className="text-center rounded" className="thumbnail block-alignwide">
                    <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/excel_2.png' alt="Excel picture" />
                    </div>
                    
                    <hr>
                    
                    <h4>Programme de formation</h4>
                    
                    <h6>Objectifs pédagogiques</h6>
                    <p>Avec cette formation Excel 2019®, vous pourrez en 45 leçons gérer des documents Excel, mettre en forme un tableau et des données, saisir des formules de calculs simples et créer des graphiques simples.</p>

                    <h6>Système d'évaluation</h6>
                    <p>Questionnaires des acquis en amont puis en fin de la formation.</p>

                    <h6>Accessibilité</h6>
                    <p>Nos formations sont adaptées au public en situation de handicap.</p>

                    <h6>Pré requis technique</h6>
                    <ul>
                    <li>Navigateur web : Edge, Chrome, Firefow, Safari</li>
                    <li>Système d'exploitation : Mac ou PC, smartphone</li>
                    </ul>

                    <hr>

                    <h4>Détail formation</h4>

                    <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg>  Prise en main</h6></summary>
                    <ul>
                    <li>Démarrer et quitter Excel</li>
                    <li>La fenêtre Excel</li>
                    <li>Ouvrir et enregistrer un classeur</li>
                    <li>Créer un classeur basé sur un modèle</li>
                    </ul></details>

                    <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg>  Saisir des données</h6></summary>
                    <ul>
                    <li>Saisir du contenu dans une cellule</li>
                    <li>Saisir des nombres</li>
                    <li>Effacer, modifier le contenu d'une cellule</li>
                    <li>Saisir des dates et des heures</li>
                    </ul></details>

                    <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg>  Sélectionner une cellule, une plage de cellule</h6></summary>
                    <ul>
                    <li>Se déplacer à l'aide des touches du clavier</li>
                    <li>Atteindre une cellule rapidement</li>
                    <li>Se déplacer dans les feuilles d'un classeur</li>
                    <li>Sélectionner une plage de cellules</li>
                    <li>Sélectionner des cellules disjointes</li>
                    <li>Sélectionner des lignes ou des colonnes</li>
                    </ul></details>

                    <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg>  Les lignes et les colonnes</h6></summary>
                    <ul>
                    <li>Insérer, supprimer une ligne</li>
                    <li>Insérer, supprimer une colonne</li>
                    <li>Modifier la hauteur d'une ligne</li>
                    <li>Masquer, afficher une ligne ou une colonne</li>
                    <li>Modifier la largeur d'une colonne</li>
                    <li>Transposer des lignes en colonnes</li>
                    </ul></details>

                    <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg>  Les différents formats</h6></summary>
                    <ul>
                    <li>Saisir des nombres</li>
                    <li>Saisir des dates</li>
                    <li>Saisir des heures</li>
                    <li>Saisir des valeurs monétaires</li>
                    </ul></details>

                    <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg>  Calculs simples</h6></summary>
                    <ul>
                    <li>Ecrire une formule dans une cellule</li>
                    <li>Ecrire des formules arithmétiques simples</li>
                    <li>Recopier des formules avec des références
                    relatives</li>
                    <li>Découvrir d'autres formules simples</li>
                    <li>Appliquer sur un cas concret</li>
                    <li>Être plus productif</li>
                    </ul></details>

                    <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg>  Les fonctions courantes</h6></summary>
                    <ul>
                    <li>Fonctions statistiques courantes NB(), MOYENNE()</li>
                    <li>Connaître l'ordre de calcul</li>
                    <li>Différencier références relatives et absolues</li>
                    <li>Afficher et imprimer des formules</li>
                    <li>Ecrire une formule de calcul</li>
                    </ul></details>

                    <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg>  Mise en forme</h6></summary>
                    <ul>
                    <li>Mettre en forme des données</li>
                    <li>Modifier la position des données</li>
                    <li>Fusionner des cellules</li>
                    <li>Appliquer des bordures aux cellules</li>
                    <li>Modifier la couleur des cellules</li>
                    <li>Reproduire la mise en forme</li>
                    </ul></details>

                    <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Premières applications</h6></summary>
                    <ul>
                    <li>Eléments de factures</li>
                    <li>Calcul d'honoraires</li>
                    <li>Grille de rémunération</li>
                    <li>Calculer une évolution</li>
                    </ul></details>
                    </div>
                `
            },
            {
                "id": "bef1fd04-230e-47e4-9c10-72ad2b68e005",
                "title": "Illustrator",
                "image": "illustrator_1.png",
                "pdfFile": "/formations/bureautique/illustrator.pdf",
                "body": `
                <div className='blog-main-content'>
                <p>Grâce à cette formation  vous pourrez maîtriser le logiciel de référence pour tous les documents d'illustration, les éléments vectoriels et autres outils pour perfectionner vos supports de communication.</p>
                
                <h4 className='title'>Vous pourrez par la suite :</h4>
                <ul><li>Créer des logos</li>
                <li>Transformer les formes et les couleurs en logos</li>
                <li>Dessiner à main levée et donner vie à vos dessins</li></ul>
                
                <h4 className='title'>Objectifs :</h4>
                <ul><li>Créer des logos et ensuite leur donner vie</li>
                <li>Conceptionner des illustration et manipuler le texte</li>
                <li>Vectoriser des images Bitmap</li></ul>
                
                <h4 className='title'>Certification :</h4>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I-formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre 2018, Décret n° 2019-565 du 6 juin 2019).</p>
                
                <h4 className='title'>Financement :</h4>
                <p>Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>
                
                <hr>
                
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/illustrator_2.png' alt="Illustrator picture" />
                </div>
                
                <hr>
                
                <h4>Programme de formation</h4>
                
                <h6>Objectifs pédagogiques</h6>
                <p>Avec cette formation Illustrator Initiation, vous pourrez en 35 modules apprendre et maîtriser les outils de bases d'Illustrator et les mettre en pratique avec des ateliers simples, concrets et créatifs.</p>

                <h6>Système d'évaluation</h6>
                <p>Questionnaires des acquis en amont puis en fin de formation.</p>

                <h6>Pré requis technique</h6>
                <ul>
                <li>Navigateur web : Edge, Chrome, Firefow, Safari</li>
                <li>Système d'exploitation : Mac ou PC, smartphone</li>
                </ul>

                <hr>

                <h4>Détail formation</h4>

                <h5>Illustrator - Niveau 1 - Initiation</h5>
                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 01 - Télécharger le logiciel</h6></summary>
                <ul>
                <li>Comment télécharger le logiciel ?</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 02 - Illustrator : prise en main</h6></summary>
                <ul>
                <li>Illustrator, à quoi ca sert ?</li>
                <li>Ouvrons ensemble Illustrator</li>
                <li>Création d'un nouveau document</li>
                <li>Espace de travail sur Illustrator</li>
                <li>Comment ouvrir un document</li>
                <li>Comment enregistrer</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 03 - Premiers pas - Outils de base</h6></summary>
                <ul>
                <li>Outil de sélection - Déplacer une forme</li>
                <li>Outil de sélection - Faire une rotation de l'objet</li>
                <li>Comprendre l'ordre des tracés dans un calque</li>
                <li>Dessiner des formes - Le rectangle</li>
                <li>Dessiner de formes - Voyons toutes les autres formes</li>
                <li>Outil de Sélection directe</li>
                <li>Exercice - Formes - Explications</li>
                <li>Exercice - Formes - Solution</li>
                <li>Outil Plume - Dessiner des lignes droites</li>
                <li>Outil Plume - Dessiner des courbes</li>
                <li>Exercice - Plume</li>
                <li>Exercice - Plume - Solution</li>
                <li>Modifier un tracé à la plume</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 04 - Atelier Créatif - Cactus</h6></summary>
                <ul>
                <li>Présentation de l'atelier Cactus</li>
                <li>Dessiner le pot du cactus</li>
                <li>Début du corps du cactus</li>
                <li>Faire les épines du cactus</li>
                <li>Finalisation du cactus</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 05 - La couleur : les bases</h6></summary>
                <ul>
                <li>Comment mettre simplement de la couleur dans une forme</li>
                <li>Différence entre RVB et CMJN</li>
                <li>Retirer une couleur - contour ou fond</li>
                <li>Comment faire un dégradé de couleur</li>
                <li>Aller plus loin avec le dégradé</li>
                <li>Utiliser l'outil Pipette</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 06 - Le Texte : Les bases</h6></summary>
                <ul>
                <li>Ecrivez - outil Texte</li>
                <li>Modifier la typo</li>
                <li>Mettre de la couleur au texte</li>
                <li>Modifier un bloc de texte</li>
                <li>Trouver une typo</li>
                <li>Ecrivez le long d'une forme - Outil texte curviligne</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 07 - Les calques et traces</h6></summary>
                <ul>
                <li>Présentation des calques</li>
                <li>Modifier les calques et déplacer les tracés</li>
                <li>Disposition des tracés</li>
                <li>Grouper des tracés entre eux</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 08 - Exercice pratique</h6></summary>
                <ul>
                <li>Télécharger et modifier un fichier ILLUSTRATOR</li>
                <li>Où et comment télécharger un document vectoriel</li>
                <li>Modifier le document</li>
                <li>Fichiers ressources</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 09 - Aller plus loin avec les outils et autres manipulations</h6></summary>
                <ul>
                <li>Faire une symétrie</li>
                <li>Faire une rotation de l'objet</li>
                <li>Mettre à l'échelle une forme et ses contours</li>
                <li>Déplacement et répéter la transformation</li>
                <li>Cutter - Ciseaux - Gomme</li>
                <li>Option de la Gomme</li>
                <li>Masque d'écrêtage - Intro</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 10 - Les contours</h6></summary>
                <ul>
                <li>Modifier la taille de contour</li>
                <li>Faire des pointillés et des flèches</li>
                <li>Profil et formes de contours</li>
                <li>Bibliothèque de forme et contour</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 11 - Atelier Créatif - Glace</h6></summary>
                <ul>
                <li>Présentation de l'atelier</li>
                <li>Dessin de la 1re Glace</li>
                <li>Dessin de la 2e glace</li>
                <li>Mettre de la couleur</li>
                <li>Mettre les effets graphiques</li>
                <li>Mettre les textures dans les formes</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 12 - Dessiner autrement avec de nouveaux outils</h6></summary>
                <ul>
                <li>Introduction à l'outil Pinceau</li>
                <li>Premiers option de l'outil pinceau</li>
                <li>Derniers option de l'outil pinceau</li>
                <li>Changer la forme du pinceau</li>
                <li>Outil crayon</li>
                <li>Outil Shaper</li>
                <li>Option de l'outil Shaper</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 13 - Les images</h6></summary>
                <ul>
                <li>Importer une image</li>
                <li>Modifier les paramètres de l'image</li>
                <li>Vectoriser des photos</li>
                <li>Vectorisation dynamique et composition</li>
                </ul></details>


                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer">
                <h6>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/></svg> 14 - Atelier Créatif - Café</h6></summary>
                <ul>
                <li>Présentation de l'atelier</li>
                <li>Création du nouveau document</li>
                <li>Vectorisation de l'image</li>
                <li>Supprimer des parties de l'image vectorisée</li>
                <li>Installer les typos</li>
                <li>Générer le texte et le mettre en couleur</li>
                <li>Équilibrer les éléments typographiques</li>
                <li>Harmoniser le logo</li>
                <li>Caler les derniers éléments entre eux</li>
                <li>Enregistrement de votre création</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 15 - Onglet Affichage</h6></summary>
                <ul>
                <li>Le mode tracé</li>
                <li>La partie « Zoom »</li>
                <li>Les règles</li>
                <li>Les repères commentés</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 16 - Atelier Créatif - Papèterie</h6></summary>
                <ul>
                <li>Présentation de l'atelier Papèterie</li>
                <li>Création du papier à en-tête - 1/2</li>
                <li>Création du papier à en-tête - 2/2</li>
                <li>Création carte de correspondance</li>
                <li>Création enveloppe</li>
                <li>Ajouter des fonds perdus et enregistrer</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 17 - Atelier Créatif - Dessin sur Photo</h6></summary>
                <ul>
                <li>Présentation de l'atelier</li>
                <li>Création du nouveau document et importation de l'image</li>
                <li>Dessin des traits sur la partie supérieure</li>
                <li>Dessin du tour du manteau</li>
                <li>Finalisation du projet</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 18 - Alignement</h6></summary>
                <ul>
                <li>Alignement de l'objet</li>
                <li>Alignement par rapport à un repère</li>
                <li>Répartition des objets</li>
                <li>Répartition de l'espacement</li>
                <li>Astuce sur l'alignement</li>
                </ul></details>


                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 19 - Pathfinder</h6></summary>
                <ul>
                <li>Le Pathfinder</li>
                <li>Forme composée avec le Pathfinder</li>
                <li>Diviser une forme avec le Pathfinder</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 20 - Atelier Créatif - La vache</h6></summary>
                <ul>
                <li>Présentation De l'atelier</li>
                <li>Explication de l'atelier</li>
                <li>Dessin de la corne et de l'oreille</li>
                <li>Dessinons la tête et le museau</li>
                <li>La tâche, les yeux et le nez</li>
                <li>Finir les dernières traces</li>
                <li>Faire la symétrie</li>
                <li>Disposition des éléments entre eux</li>
                <li>Mise en couleur de la vache</li>
                <li>Utiliser le Pathfinder</li>
                <li>Mettre l'ombre à la vache</li>
                <li>A vous de refaire le cochon</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 21 - Exporter et partager ses créations</h6></summary>
                <ul>
                <li>Les différents exports</li>
                <li>Introduction à la Bibliothèque</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 22 - Onglet Effet : Spécial</h6></summary>
                <ul>
                <li>Présentation</li>
                <li>Arrondis</li>
                <li>Contour progressif</li>
                <li>Griffonnage</li>
                <li>Lueur externe</li>
                <li>Lueur interne</li>
                <li>Ombre portée</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 23 - Atelier Créatif - Découpe Papier</h6></summary>
                <ul>
                <li>Présentation De l'atelier</li>
                <li>Création du nouveau document</li>
                <li>Dessin des premières vagues</li>
                <li>Arrondir les vagues</li>
                <li>Finaliser le dessin des vagues</li>
                <li>Mise en couleurs des vagues</li>
                <li>Dessin du palmier</li>
                <li>Ajout des ombres portées</li>
                <li>Découpe du palmier</li>
                <li>Finaliser les derniers éléments</li>
                <li>Attribuer le masque d'écrêtage</li>
                <li>Ajuster les dernières retouches</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 24 - Les symboles</h6></summary>
                <ul>
                <li>Créer un nouveau symbole</li>
                <li>Pulvérisation de symbole</li>
                <li>Comment Modifier les symboles</li>
                </ul></details>

                
                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 25 - Atelier Créatif - Paysage</h6></summary>
                <ul>
                <li>Présentation de l'atelier Paysage</li>
                <li>Création du nouveau document</li>
                <li>Dessin du fond, la lune et le soleil</li>
                <li>Créer les chaînes de montage à la plume</li>
                <li>Dessin des cactus et 1er plan avec l'outil pinceau</li>
                <li>Mettre le dégradé au fond</li>
                <li>Travail des dégradés sur les autres formes</li>
                <li>Création des symboles Étoiles</li>
                <li>Pulvériser les symboles</li>
                <li>Finalisation Du projet</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 26 - Atelier Créatif - Création d'une mise en page : Newsletter</h6></summary>
                <ul>
                <li>Présentation de l'atelier Newsletter</li>
                <li>Créer le nouveau document</li>
                <li>Ajouter les repères à notre création</li>
                <li>Plaçons les bases de la structure de la page</li>
                <li>Ajouter les images dans les formes</li>
                <li>Caler le texte avec les photos</li>
                <li>Création du titre de la newsletter</li>
                <li>Ajout des éléments graphiques - finalisation du projet</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 27 - Atelier Créatif - Logo Marin d'eaux douces</h6></summary>
                <ul>
                <li>Présentation de l'atelier Marin d'eaux douces</li>
                <li>Création du nouveau document</li>
                <li>Création de la casquette du marin</li>
                <li>Dessinons le début du visage</li>
                <li>Finalisation des formes du visage</li>
                <li>Ajout des derniers éléments de l'illustration</li>
                <li>Faire les cercles autour de l'illustration</li>
                <li>Ecrire le texte le long des cercles</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 28 - Atelier Créatif - Affiche Nuits étoilées</h6></summary>
                <ul>
                <li>Présentation De l'atelier Affiche</li>
                <li>Création du nouveau document</li>
                <li>Création du fond et de la 1re forme</li>
                <li>Finalisation des dernières formes du paysage</li>
                <li>Dessin du cœur</li>
                <li>Création des arbres et de la lune</li>
                <li>Dessin des illustrations sapins</li>
                <li>Dessinons les dernières illustrations</li>
                <li>Ajout des cercles blancs</li>
                <li>Mise en place des textes</li>
                <li>Finalisation De la création</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 29 - Atelier Créatif - Reproduire le logo Apple</h6></summary>
                <ul>
                <li>Présentation Et ouverture du fichier</li>
                <li>Dessin de la moitié de la pomme</li>
                <li>Corriger le tracé</li>
                <li>Symétrie de la pomme</li>
                <li>Morsure de la pomme</li>
                <li>Dessiner la queue de la pomme</li>
                <li>Changer la couleur et joindre les points</li>
                <li>Enregistrement</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 30 - Atelier Créatif - Redessiner le logo Adidas</h6></summary>
                <ul>
                <li>Présentation</li>
                <li>Création du 1er cercle</li>
                <li>Dupliquer les cercles</li>
                <li>Création des barres des lettres</li>
                <li>Création du S</li>
                <li>Création du symbole</li>
                <li>Couleur et enregistrement</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 31 - Atelier Créatif - Dessiner un picto MAP</h6></summary>
                <ul>
                <li>Présentation</li>
                <li>Création du nouveau document</li>
                <li>Faire le cercle</li>
                <li>Modifier la forme</li>
                <li>Mise en couleur de la 1re moitié</li>
                <li>Mise en couleur de la 2e moitié</li>
                <li>Cercle centré et ombre portée</li>
                </ul></details>
                
                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 32 - Atelier Créatif - Redessiner à partir d'un dessin</h6></summary>
                <ul>
                <li>Présentation Du logo</li>
                <li>Vectorisation Dynamique du planisphère</li>
                <li>Finalisez le dessin</li>
                <li>Ajouter le texte</li>
                <li>Vectoriser Le texte et enregistrez votre illustration</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 33 - Atelier Créatif - Créer un logo pour une boutique de Vélo</h6></summary>
                <ul>
                <li>Présentation Du logo</li>
                <li>Dessin écrou</li>
                <li>Texte de fin</li>
                <li>Enregistrement</li>
                <li>Dessin montagne</li>
                <li>Début du texte</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 34 - Atelier Créatif - Créez votre carte de Visite</h6></summary>
                <ul>
                <li>Présentation De l'atelier</li>
                <li>Création du nouveau document</li>
                <li>Les fonds perdus</li>
                <li>Création du recto</li>
                <li>Création du verso</li>
                <li>Enregistrement Aux différents formats</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 35 - Atelier Créatif - Carte de Noël</h6></summary>
                <ul>
                <li>Présentation</li>
                <li>Nouveau document</li>
                <li>Création de la 1re boule de noël</li>
                <li>Création de la 2e boule de noël</li>
                <li>Création du 1er sapin</li>
                <li>Création du 2e sapin</li>
                <li>Optimiser son plan de travail</li>
                <li>Disposition des boules de noël</li>
                <li>Disposition des sapins de noël</li>
                <li>Création des formes sous le texte</li>
                <li>Finalisation des écritures</li>
                </ul></details>
                </div>
            `
            },
            {
                "id": "0d3196a9-c464-4fd0-9654-25e201770367",
                "title": "Indesign",
                "image": "indesign_1.png",
                "pdfFile": "/formations/bureautique/indesign.pdf",
                "body": `
                <div className='blog-main-content'>
                <p>Grâce à cette formation vous pourrez créer des contenus sur InDesign en apprenant les différents outils de mise en page.</p>
                
                <h4 className='title'>Vous pourrez par la suite :</h4>
                <ul><li>Créer des dépliants et brochures</li>
                <li>Créer des magazines, journaux et livres</li>
                </ul>
                
                <h4 className='title'>Objectifs :</h4>
                <ul><li>Créer une mise en page et la modifier</li>
                <li>Publier des contenus adaptés aux tablettes</li></ul>
                
                <h4 className='title'>Certification :</h4>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I-formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre 2018, Décret n° 2019-565 du 6 juin 2019).</p>
                
                <h4 className='title'>Financement :</h4>
                <p>Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>
                
                <hr>
                
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/indesign_2.png' alt="Indesign picture" />
                </div>
                
                <hr>
                
                <h4>Programme de formation</h4>
                
                <h6>Objectifs pédagogiques</h6>
                <p>Avec cette formation InDesign Initiation, vous pourrez en 24 modules apprendre et maîtriser les outils de bases d'InDesign et les mettre en pratique avec des ateliers simples, concrets et créatifs.</p>

                <h6>Système d'évaluation</h6>
                <p>Questionnaires des acquis en amont puis en fin de formation.</p>

                <h6>Pré requis technique</h6>
                <ul>
                <li>Navigateur web : Edge, Chrome, Firefow, Safari</li>
                <li>Système d'exploitation : Mac ou PC, smartphone</li>
                </ul>

                <hr>

                <h4>Détail formation</h4>

                <h5>InDesign - Niveau 1 - Initiation</h5>
                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 01 - Télécharger Le logiciel</h6></summary>
                <ul>
                <li>Télécharger Adobe Indesign</li>
                </ul></details></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 02 - Les premiers pas</h6></summary>
                <ul>
                <li>Ouvrir le logiciel</li>
                <li>Création du nouveau document</li>
                <li>Présentation Rapide de l'interface</li>
                <li>Personnalisation De l'espace de travail</li>
                <li>Télécharger un template gratuit</li>
                <li>Naviguer entre les page</li>
                <li><li>Naviguer entre les page</li></li>
                <li>Exercice - Solution</li>
                <li>Quiz - Premiers Pas</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 03 - Paramétrer vos documents</h6></summary>
                <ul>
                <li>Présentation des repères d'un document</li>
                <li>Qu'est-ce que le fond perdu ?</li>
                <li>Changer le format de son document</li>
                <li>Changer les marges et colonnes</li>
                <li>Créer des pages et les supprimer</li>
                <li>Astuce : Onglet propriété</li>
                <li>Quiz - Paramétrer vos documents</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 04 - Ouvrir - enregistrer des fichiers Indesign</h6></summary>
                <ul>
                <li>Présentation D'un fichier Indesign</li>
                <li>Enregistrer son document</li>
                <li>A quoi sert un dossier d'assemblage ?</li>
                <li>Comment Faire un dossier d'assemblage</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 05 - Le Texte</h6></summary>
                <ul>
                <li>Créer un bloc de texte</li>
                <li>Changer les caractères de texte</li>
                <li>Modifier Les caractères individuellement</li>
                <li>Option de paragraphe</li>
                <li>Créer des colonnes dans un bloc</li>
                <li>Option de bloc de texte</li>
                <li>Exercice - Instruction</li>
                <li>Exercice - Solution</li>
                <li>Quiz - Le texte</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 06 - Dessiner des formes simples</h6></summary>
                <ul>
                <li>Comment dessiner un rectangle et le modifier ?</li>
                <li>Dessiner un carré et une ellipse</li>
                <li>Comment dessiner des polygones ?</li>
                <li>Option des contours de formes</li>
                <li>Outil trait</li>
                <li>Faire des lignes droites avec l'outil Plume</li>
                <li>Dessiner des courbes avec l'outil Plume</li>
                <li>Outil de sélection directe - La flèche blanche</li>
                <li>Plume Plus et Plume Moins</li>
                <li>Exercice - Présentation</li>
                <li>Exercice - Solution</li>
                <li>Quiz - Dessiner dans InDesign</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 07 - Les contours</h6></summary>
                <ul>
                <li>Contours - Les premières options</li>
                <li>Les types de contour</li>
                <li>Les types de contour</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 08 - La couleur</h6></summary>
                <ul>
                <li>Différence Entre RVB et CMJN</li>
                <li>Mettre en couleur dans une forme</li>
                <li>Mettre de la couleur au texte</li>
                <li>Comment créer un dégradé de couleur dans une forme</li>
                <li>Présentation Du nuancier</li>
                <li>Créer vos couleurs pour le nuancier</li>
                <li>Outil pipette</li>
                <li>Quiz - Couleur et contour</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 09 - Atelier créatif Ticket Événement</h6></summary>
                <ul>
                <li>Présentation De l'atelier</li>
                <li>Création du nouveau document et du fond</li>
                <li>Création de contour</li>
                <li>Recherche de typo et mise en place du titre</li>
                <li>Faire les formes sur le côté</li>
                <li>Finaliser le ticket</li>
                <li>Enregistrement</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 10 - Les images</h6></summary>
                <ul>
                <li>Importer simplement des images</li>
                <li>Déplacer l'image dans le cadre</li>
                <li>Modifier La taille du cadre et de l'image en même
                temps</li>
                <li>Ajustement des images</li>
                <li>Mettre une image dans une forme</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 11 - Le texte - Quelques options supplémentaires</h6></summary>
                <ul>
                <li>Mettre tout en capitales, indice et autres</li>
                <li>Habillage de texte par rapport au cadre de sélection</li>
                <li>Les autres options de l'habillage de texte</li>
                <li>Ecrire le texte le long d'une forme</li>
                <li>Quiz - Images et texte</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 12 - Atelier créatif - Menu Café</h6></summary>
                <ul>
                <li>Présentationde l'atelier</li>
                <li>Création du nouveau document</li>
                <li>Mise en place du fond et des grains de café</li>
                <li>Mise en place du titre</li>
                <li>Ecrire le 1er bloc texte</li>
                <li>Finalisation du menu</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 13 - Affichage et repères</h6></summary>
                <ul>
                <li>Placer des règles sur le document</li>
                <li>Les repères commentés</li>
                <li>Grille Et magnétisme</li>
                <li>Régler les paramètres de la grille</li>
                </ul></details>


                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 14 - Les gabarits</h6></summary>
                <ul>
                <li>Créer son premier gabarit</li>
                <li>Créer un nouveau gabarit</li>
                <li>Libérer les éléments de gabarit</li>
                <li>Quiz - Repères et gabarits</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 15 - Alignement</h6></summary>
                <ul>
                <li>Comment aligner les objets entre eux</li>
                <li>Répartition de l'espace</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 16 - Atelier créatif - Papier En-tête</h6></summary>
                <ul>
                <li>Présentationde l'atelier</li>
                <li>Création du nouveau document</li>
                <li>Dessiner la partie de gauche</li>
                <li>Corriger l'alignement</li>
                <li>Placer les images sur le document</li>
                <li>Utiliser le texte pour mettre les infos</li>
                <li>Ecrire le bloc de texte et signature</li>
                <li>Libérer les éléments de gabarit</li>
                <li>Enregistrer le document et l'assembler</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 17 - Les effets</h6></summary>
                <ul>
                <li>Transparence d'un objet ou d'un texte</li>
                <li>Effet ombre portée</li>
                <li>Le contour progressif</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 18 - Atelier créatif - Affiche</h6></summary>
                <ul>
                <li>Présentation de l'atelier</li>
                <li>Création du nouveau document</li>
                <li>Dessin des premières formes</li>
                <li>Créer les dégradés et le cercle</li>
                <li>Ombres portées et insertion d'image</li>
                <li>Générer les titres</li>
                <li>Finalisation de la création de l'affiche</li>
                </ul></details>


                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 19 - Les liens des images</h6></summary>
                <ul>
                <li>Qu'est-ce qu'un lien ?</li>
                <li>Modifier un lien et incorporer</li>
                <li>Corriger un lien manquant</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 20 - Corriger Les erreurs</h6></summary>
                <ul>
                <li>Corriger une erreur d'image</li>
                <li>Corriger une erreur de texte en excès</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 21 - Exportations</h6></summary>
                <ul>
                <li>Exporter en PDF</li>
                <li>Mettre des traits de coupe sur son PDF</li>
                <li>Exporter pour le web et en JPG</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 22 - Atelier créatif - Newsletter Quicksilver</h6></summary>
                <ul>
                <li>Présentation De la newsletter</li>
                <li>Création du format de document</li>
                <li>Mettre la 1re image</li>
                <li>Installer la typo et le premier bloc de texte</li>
                <li>Mettre le bouton et dupliquer le bloc</li>
                <li>Mettre à jour le 2e bloc</li>
                <li>Finalisation de la newsletter</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 23 - Atelier créatif - Carte de visite</h6></summary>
                <ul>
                <li>Présentation De l'atelier</li>
                <li>Création du nouveau document</li>
                <li>Importer la photo dans la forme</li>
                <li>Dessiner le carré de couleur</li>
                <li>Finaliser le recto</li>
                <li>Importer l'image du verso</li>
                <li>Ecrire le titre du verso</li>
                <li>Finaliser le dernier bloc de texte</li>
                <li>Bien enregistrer et exporter pour l'imprimeur</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 24 - Atelier créatif - Catalogue 4 pages</h6></summary>
                <ul>
                <li>Présentation De l'atelier</li>
                <li>Création du nouveau document</li>
                <li>Création de la 1re de couverture</li>
                <li>Importer les premières images</li>
                <li>Mise en place du bloc texte</li>
                <li>Finaliser le bloc information</li>
                <li>Création de la page 3</li>
                <li>Création de la dernière de couverture</li>
                <li>Enregistrement et export</li>
                </ul></details>
                </div>
                `
            },
            {
                "id": "8f875ce9-e51b-4798-9c0c-0c8a7fcc70c5",
                "title": "Photoshop",
                "image": "photoshop_1.png",
                "pdfFile": "/formations/bureautique/photoshop.pdf",
                "body": `<div className='blog-main-content'>
                <p>Grâce à Photoshop vous pourrez retoucher vos photos ou concevoir des graphiques.</p>
                
                <h4 className='title'>Vous pourrez par la suite :</h4>
                <ul><li>Créer des images sensationnelles</li>
                <li>Créer des illustrations originales</li>
                <li>Créer des œuvres d'art</li>
                </ul>
                
                <h4 className='title'>Objectifs :</h4>
                <p>Avec cette formation Photoshop, vous pourrez apprendre et maîtriser les outils de de Photoshop, réaliser des photomontages, du détourage d'images et des créations de visuels graphiques.</p>
                
                <h4 className='title'>Certification :</h4>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I-formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre 2018, Décret n° 2019-565 du 6 juin 2019).</p>
                
                <h4 className='title'>Financement :</h4>
                <p>Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>
                
                <hr>
                
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/photoshop_2.png' alt="photoshop picture" />
                <p>Ajoutez des effets, mélangez les couleurs et modifiez l'expression d'un visage.</p>
                </div>

                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/photoshop_3.png' alt="photoshop picture" />
                <p>Taguez un mur et créez des effets d'aquarelle et de peinture à l'huile.</p>
                </div>
                
                <hr>
                
                <h4>Programme de formation</h4>
                
                <h6>Objectifs pédagogiques</h6>
                <p>Avec cette formation InDesign Initiation, vous pourrez en 24 modules apprendre et maîtriser les outils de bases d'InDesign et les mettre en pratique avec des ateliers simples, concrets et créatifs.</p>

                <h6>Système d'évaluation</h6>
                <p>Questionnaires des acquis en amont puis en fin de formation.</p>

                <h6>Accessibilité</h6>
                <p>Nos formations sont adaptées au public en situation de handicap</p>

                <h6>Pré requis technique</h6>
                <ul>
                <li>Navigateur web : Edge, Chrome, Firefow, Safari</li>
                <li>Système d'exploitation : Mac ou PC, smartphone</li>
                </ul>

                <hr>

                <h4>Détail formation</h4>

                <h5>Photoshop - Niveau 1 - Initiation</h5>
                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 01 - Télécharger Le logiciel</h6></summary>
                <ul>
                <li>Comment télécharger le logiciel ?</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 02 - Photoshop - Prise en main</h6></summary>
                <ul>
                <li>Photoshop, à quoi ça sert ?</li>
                <li>Ouvrons Photoshop</li>
                <li>Création d'un nouveau document</li>
                <li>L'interface de Photoshop</li>
                <li>Ouvrir et importer une image</li>
                <li>Enumération des outils</li>
                <li>Déplacez-vousdans le document</li>
                <li>Comment enregistrer</li>
                <li>Autres options pour zoomer</li>
                <li>Personnaliser son espace de travail</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 03 - Les images - Les bases</h6></summary>
                <ul>
                <li>Informations Sur une image ouverte</li>
                <li>Les pixels, c'est quoi ?</li>
                <li>Redimensionner une image</li>
                <li>Transformer la taille d'une image</li>
                <li>Comment Faire une rotation d'une image</li>
                <li>Effet miroir: faire des symétries</li>
                <li>Cadre</li>
                <li>Outil recadrage</li>
                <li>Corriger l'inclinaison d'une image</li>
                <li>Réglages automatique d'une image</li>
                <li>Luminosité - Contraste</li>
                <li>Réglage de la courbe</li>
                <li>Réglage en noir et blancdre</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 04 - Les calques- Les bases</h6></summary>
                <ul>
                <li>Ouvrons un fichier Photoshop</li>
                <li>Qu'est-ce qu'un calque ?</li>
                <li>Les différents calques</li>
                <li>Créer un nouveau calque - Renommer et disposer les calques</li>
                <li>Les différentes actions sur les calques</li>
                <li>Déplacer et redimensionner les calques</li>
                <li>Présentation De l'exercice</li>
                <li>Solution de l'exercice</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 05 - La Sélection - Les bases</h6></summary>
                <ul>
                <li>Sélectionner un sujet</li>
                <li>Outil de sélection rapide</li>
                <li>Outil baguette magique</li>
                <li>Outil de sélection d'objet</li>
                <li>Outil lasso</li>
                <li>Outil de sélection Rectangle et Ellipse</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 06 - Atelier créatif- Changerle fond d'un personnage</h6></summary>
                <ul>
                <li>Changer le fond du personnage</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 07 - La couleur- Les bases</h6></summary>
                <ul>
                <li>Calque de remplissage - Mettre un fond de couleur</li>
                <li>Pot de peinture</li>
                <li>Introduction au dégradé</li>
                <li>Outil dégradé : plus de détails</li>
                <li>Le pinceau</li>
                <li>Différence entre RVB et CMJN</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 08 - Le texte - Les bases</h6></summary>
                <ul>
                <li>Exercice texte - Ouvrir et recadrer</li>
                <li>Générer du texte</li>
                <li>Changer les propriétés du texte</li>
                <li>Exercice texte - Trouver une typo</li>
                <li>Bloc de texte</li>
                <li>Exercice texte - Finaliser la mise en page</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 09 - Atelier créatif</h6></summary>
                <ul>
                <li>Présentation D'un produit : l'Iphone</li>
                <li>Présentation De l'atelier iPhone</li>
                <li>Détourer les iPhones</li>
                <li>Ajuster la taille des iPhones</li>
                <li>Mettre le fond en couleur</li>
                <li>Ajoutons le texte</li>
                <li>Dessinons les cercles de couleur</li>
                <li>Ecrire les derniers textes</li>
                <li>Finalisation de la création</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 10 - Les formes - Les bases</h6></summary>
                <ul>
                <li>Générer un rectangle et changer les paramètres</li>
                <li>Créer d'autres formes</li>
                <li>Modifier les formes</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 11 - Atelier créatif- Florence</h6></summary>
                <ul>
                <li>Importer une image</li>
                <li>Modifier les paramètres de l'image</li>
                <li>Vectoriser des photos</li>
                <li>Vectorisation dynamique et composition</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 12 - Les filtres - Les bases</h6></summary>
                <ul>
                <li>Présentation Des flous</li>
                <li>Appliquer un flou gaussien</li>
                <li>Flou et profondeur de champ</li>
                <li>Présentation Rapide de la galerie de filtres</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 13 - Atelier créatif- Surf session</h6></summary>
                <ul>
                <li>Présentationde l'atelier Surf</li>
                <li>Création du nouveau document et des cadres photos</li>
                <li>Importer les images dans les cadres</li>
                <li>Autres méthodes pour séparer les images</li>
                <li>Ajouter les filtres</li>
                <li>Ecrire le texte et finaliser la création</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 14 - Les Images - Les retouches</h6></summary>
                <ul>
                <li>Outil tampon - A quoi ça sert ?</li>
                <li>Outil correcteur localisé</li>
                <li>Outil Correcteur</li>
                <li>Outil Pièce</li>
                <li>Déplacement de base sur le contenu</li>
                <li>Exercice - Utiliser l'outil correcteur localisé</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 15 - Les calques- Nouveaux Paramètres</h6></summary>
                <ul>
                <li>Introduction aux calques de réglages</li>
                <li>Voyons les autres calques de réglages</li>
                <li>Disposition des calques de réglages</li>
                <li>Calques de fusion - Ombre portée</li>
                <li>Autres calques de fusion</li>
                <li>Changer l'opacité d'un calque</li>
                <li>Masque de fusion - Introduction</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 16 - Atelier créatif- Jungle Néon</h6></summary>
                <ul>
                <li>Présentationde l'atelier Jungle Néon</li>
                <li>Ouvrir les documents</li>
                <li>Ecrire le mot JUNGLE</li>
                <li>Ajouter le masque de fusion au texte</li>
                <li>Ajout des effets de NEON</li>
                <li>Rajouter les touches de couleur</li>
                <li>Ajouter les calques de réglages</li>
                <li>Enregistrement</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 17 - La sélection - Apprenons davantage</h6></summary>
                <ul>
                <li>Intervertirla sélection</li>
                <li>Dilater et contacter la sélection</li>
                <li>Contour progressifde la sélection</li>
                <li>Outil plume- Faire des lignes droites</li>
                <li>Outil plume- Faire des courbes</li>
                <li>Transformer son tracé en sélection</li>
                <li>Modifier le tracé</li>
                <li>Sélectionner la tasse avec la plume</li>
                <li>Améliorer Le contour</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 18 - Atelier créatif- Effet Glitch</h6></summary>
                <ul>
                <li>Présentationde l'atelier Glitch</li>
                <li>Création des fonds en noir et blanc</li>
                <li>Modifier Les styles du calque - Travail sur les couches RVB</li>
                <li>Faire le décalage de l'image</li>
                <li>Finaliser l'effet Glitch</li>
                <li>Effet Glitch avec l'ours</li>
                </ul></details>


                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 19 - La couleur- Plus de paramètres</h6></summary>
                <ul>
                <li>Formes de pinceauxplus avancées</li>
                <li>Télécharger des formes de pinceaux</li>
                <li>Utiliser l'outil Pipette</li>
                <li>Le nuancier - Introduction</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 20 - Autres Fonctionnalités</h6></summary>
                <ul>
                <li>Présentationdu changement de ciel</li>
                <li>Changer un 1er ciel</li>
                <li>Importer ses propres ciels</li>
                <li>Aller plus loin avec cette fonction</li>
                <li>Changer les expressions du visage - Portrait Femme</li>
                <li>Changer les expressions du visage - Portrait Homme</li>
                <li>Coloriser ces vieillesphotos</li>
                <li>Affichage - Extra et Règles</li>
                <li>Affichage - Magnétisme</li>
                <li>Affichage - Magnétisme</li>
                <li>Installer des plugins sur Photoshop</li>
                <li>Importer une image depuis son iPhone</li>
                <li>L'Historique, c'est quoi ?</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 21 - Atelier créatif - Affiche Nike</h6></summary>
                <ul>
                <li>Présentationde l'atelier Nike</li>
                <li>Création du nouveau document</li>
                <li>Trace à la plume</li>
                <li>Transformer son tracé en sélection</li>
                <li>Travail sur le fond</li>
                <li>Ajouter l'ombre portée à la forme rouge</li>
                <li>Ajout de l'ombre à la basket Nike</li>
                <li>Ajout du logo Nike</li>
                <li>Finalisation du visuel</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 22 - Atelier créatif- Retouche Photo Simple</h6></summary>
                <ul>
                <li>Présentationde l'atelier Retouche</li>
                <li>Outil correcteur localisé</li>
                <li>Réglage de la teinte (saturation)</li>
                <li>Réglage de la luminosité et du contraste</li>
                <li>Portrait 2 - Outil correcteur localisé</li>
                <li>Lisser la peau</li>
                <li>Luminosité - Contraste et teinte saturation</li>
                <li>Création d'un halo de lumière</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 23 - Atelier créatif- Strech Pixel</h6></summary>
                <ul>
                <li>Présentationde l'atelier Pixel</li>
                <li>Détourage de la danseuse</li>
                <li>Création de la bande de pixels</li>
                <li>Effet coordonnéespolaires</li>
                <li>Ajuster le cercle des pixels</li>
                <li>Ajouter les ombres portées</li>
                <li>Calques de réglages pour dynamiser la création</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 24 - Atelier créatif - Post pour les réseaux sociaux</h6></summary>
                <ul>
                <li>Présentation De l'atelier Réseaux sociaux</li>
                <li>Création des différents gabarits</li>
                <li>Insérer la photo dans les différents gabarits</li>
                <li>Générer le texte</li>
                <li>Mettre le texte sur tous les posts</li>
                <li>Enregistrer les différents plans de travail</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 25 - Atelier créatif- Affiche Sport Air Jordan</h6></summary>
                <ul>
                <li>Présentationde l'atelier Affiche Sport</li>
                <li>Nouveau document</li>
                <li>Détourage du sujet</li>
                <li>Préparationdes fonds</li>
                <li>Création de la typo</li>
                <li>Détourage du Jordan Dunk</li>
                <li>Finalisation de l'affiche</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 26 - Atelier créatif- Double exposition</h6></summary>
                <ul>
                <li>Présentationde l'atelier Affiche Sport</li>
                <li>Nouveau document</li>
                <li>Détourage du sujet</li>
                <li>Préparationdes fonds</li>
                <li>Création de la typo</li>
                <li>Détourage du Jordan Dunk</li>
                <li>Finalisation de l'affiche</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 27 - Atelier créatif- Nina Ricci</h6></summary>
                <ul>
                <li>Présentationde l'atelier Nina Ricci</li>
                <li>Paramétrage et création du nouveau document</li>
                <li>Détourage du flacon</li>
                <li>Mettre à l'échelledu flacon</li>
                <li>Faire le reflet du flacon</li>
                <li>Faire le fond avec un dégradé</li>
                <li>Ajouter le texte</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 28 - Atelier créatif- Maquette Magazine</h6></summary>
                <ul>
                <li>Présentationde l'atelier</li>
                <li>Création du nouveau document</li>
                <li>Créer les colonnes de texte</li>
                <li>Paramétrer les colonnes</li>
                <li>Choisir son mode d'alignement de texte</li>
                <li>Travail du masque de fusion sur l'image</li>
                <li>Finalisation de la création</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 29 - Atelier créatif- Portrait Pop Art</h6></summary>
                <ul>
                <li>Présentationde l'atelier Portrait</li>
                <li>Mise en noir et blanc</li>
                <li>Détourage du portrait</li>
                <li>Effet sérigraphie</li>
                <li>Mise en couleur</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 30 - Atelier créatif - Tour Eiffel Avant/Après</h6></summary>
                <ul>
                <li>Présentationde l'atelier Portrait</li>
                <li>Mise en noir et blanc</li>
                <li>Détourage du portrait</li>
                <li>Effet sérigraphie</li>
                <li>Mise en couleur</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 31 - Atelier créatif- Banana Style</h6></summary>
                <ul>
                <li>Présentation De l'atelier Banana Style</li>
                <li>Nouveau document et détourage de la banane</li>
                <li>Découpe banane crayon</li>
                <li>Créer le dégradé du fond</li>
                <li>Ajout de la minede crayon</li>
                <li>Ajouter l'ombre portée</li>
                <li>Ajouter le texte</li>
                <li>Enregistrer votre document</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 32 - Atelier créatif- Coca-Cola</h6></summary>
                <ul>
                <li>Présentationde l'atelier</li>
                <li>Création du nouveau document</li>
                <li>Détourage plume</li>
                <li>Transformer le tracé en sélection</li>
                <li>Mise à l'échelle des bouteilles</li>
                <li>Dégradé</li>
                <li>Reflet des bouteilles</li>
                <li>Création des bandes de couleur</li>
                <li>Masque de fusion</li>
                <li>Incrustation Du logo</li>
                <li>Enregistrement Du document</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 33 - Atelier créatif- Basket en lévitation</h6></summary>
                <ul>
                <li>Présentationde l'atelier Basket</li>
                <li>Détourage à la plume de la basket</li>
                <li>Correction du tracé de détourage</li>
                <li>Transformer le tracé en sélection</li>
                <li>Améliorer la sélection</li>
                <li>Ajouter le flou à l'image de fond</li>
                <li>Apporter la basket sur le document final</li>
                <li>Importer les effets lumineux</li>
                <li>Intégrer La ville au mieux les lumières</li>
                <li>Enregistrement de l'atelier Basket En lévitation</li>
                </ul></details>
                </div>`
            },
            {
                "id": "c3ee03b5-b67a-42f2-b034-a1e1825145e8",
                "title": "Powerpoint",
                "image": "powerpoint_1.png",
                "pdfFile": "/formations/bureautique/powerpoint.pdf",
                "body": `<div className='blog-main-content'>
                <p>Grâce à Photoshop vous pourrez retoucher vos photos ou concevoir des graphiques.</p>
                
                <h4 className='title'>Vous pourrez par la suite :</h4>
                <ul><li>Maîtriser la mise en forme</li>
                <li>Créer des diapositives vivantes</li>
                </ul>
                
                <h4 className='title'>Objectifs :</h4>
                <p>Uniformiser la mise en page des diapositives.
                Construire avec méthode et rapidité une présentation intégrant du texte et des illustrations.</p>
                
                <h4 className='title'>Certification :</h4>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I-formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre 2018, Décret n° 2019-565 du 6 juin 2019).</p>
                
                <h4 className='title'>Financement :</h4>
                <p>Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>
                
                <hr>
                
                <h4>Programme de formation</h4>
                
                <h6>Objectifs pédagogiques</h6>
                <p>Avec cette formation PowerPoint 2019®, vous saurez en 42 leçons définir la ligne graphique d'une présentation pour uniformiser la mise en page des diapositives, construire avec méthode et rapidité une présentation intégrant du texte et des illustrations,mettre en forme les contenus sur diapositives et créer des animations simples.</p>

                <h6>Système d'évaluation</h6>
                <p>Questionnaires des acquis en amont puis en fin de formation.</p>

                <h6>Accessibilité</h6>
                <p>Nos formations sont adaptées au public en situation de handicap</p>

                <h6>Pré requis technique</h6>
                <ul>
                <li>Navigateur web : Edge, Chrome, Firefow, Safari</li>
                <li>Système d'exploitation : Mac ou PC, smartphone</li>
                </ul>

                <hr>

                <h4>Détail formation: : PowerPoint 2019</h4>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les règles de la présentation</h6></summary>
                <ul>
                <li>Les règles d'écriture sur les visuels à présenter</li>
                <li>Les polices de caractères</li>
                <li>Les couleurs</li>
                <li>Une présentation réussie</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Prise en main</h6></summary>
                <ul>
                <li>Démarrer et quitter PowerPoint</li>
                <li>L'interface PowerPoint</li>
                <li>L'affichage sous PowerPoint</li>
                <li>Créer une présentation</li>
                <li>Afficher une présentation</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Personnaliser et modifier d'une présentation</h6></summary>
                <ul>
                <li>Créer votre première présentation</li>
                <li>Appliquer un thème</li>
                <li>Modifier Le jeu de couleurs et de polices d'un thème</li>
                <li>Enregistrer un thème personnalisé</li>
                <li>Modifier L'ordre des diapositives dans une présentation</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Mettre En forme les diapositives</h6></summary>
                <ul>
                <li>Saisir du texte</li>
                <li>Sélectionner, déplacer, et copier du texte</li>
                <li>Modifier La police, la taille et les attributs de mise en forme</li>
                <li>Modifier l'alignement, l'interligne, l'espacement des paragraphes</li>
                <li>Modifier La casse du texte</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Insérer Une image</h6></summary>
                <ul>
                <li>Insérer une image à partir d'un fichier</li>
                <li>Insérer une image en ligne</li>
                <li>Redimensionner une image</li>
                <li>Recadrer une image et modifier la luminosité et le contraste</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Modifier des images</h6></summary>
                <ul>
                <li>Rendre une zone d'image transparente et la recolorier</li>
                <li>Modifier l'apparence d'une image</li>
                <li>Utiliser le volet Format de l'image</li>
                <li>Créer un album Photo</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les techniques du tableau</h6></summary>
                <ul>
                <li>Insérer un tableau simple</li>
                <li>Insérer, supprimer des lignes et des colonnes</li>
                <li>Modifier la hauteur des lignes, largeur des colonnes</li>
                <li>Mettre en forme tableau et cellules</li>
                <li>Dessiner un tableau</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les graphiques</h6></summary>
                <ul>
                <li>Insérer un graphique</li>
                <li>Modifier les données d'un graphique</li>
                <li>Modifier un graphique</li>
                <li>Insérer un graphique SmartArt ou diagramme</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Dessiner avec PowerPoint</h6></summary>
                <ul>
                <li>Insérer une formeprédéfinie</li>
                <li>Insérer du texte dans une forme</li>
                <li>Dessiner une forme libre</li>
                <li>Aligner, grouper, dissocier des objets</li>
                <li>Organiser des objets</li>
                </ul></details>
                </div>`
            },
            {
                "id": "ccffbb4c-6bc6-4e7c-abed-888688cbb197",
                "title": "Sketchup",
                "image": "sketchup_1.png",
                "pdfFile": "/formations/bureautique/sketchup.pdf",
                "body": `<div className='blog-main-content'>
                <p>Grâce à cette formation vous pourrez créer des graphiques en 3D vous permettant de réaliser des maquettes, des plans et des modèles en 3D.</p>
                
                <h4 className='title'>Vous pourrez par la suite :</h4>
                <ul><li>onceptualiser, créer et valider des modèles de maquettes tridimensionnelles</li>
                <li>Manipuler des objets et éléments en 3D</li>
                </ul>
                
                <h4 className='title'>Objectifs :</h4>
                <p>Utiliser en autonomie le logiciel. Créer des plans en 3D.</p>
                
                <h4 className='title'>Certification :</h4>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I-formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre 2018, Décret n° 2019-565 du 6 juin 2019).</p>
                
                <h4 className='title'>Financement :</h4>
                <p>Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>
                
                <hr>
                
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/sketchup_2.png' alt="sketchup picture" />
                <p>Créer vos maquettes, plans et modèles en 3D.</p>
                </div>

                <hr>
                
                <h4>Programme de formation</h4>
                
                <h6>Objectifs pédagogiques</h6>
                <p>Maîtriser les fonctionnalités du logiciel SketchUp afin de pouvoir l'utiliser en autonomie..</p>

                <h6>Système d'évaluation</h6>
                <p>Questionnaires des acquis en amont puis en fin de formation.</p>

                <h6>Accessibilité</h6>
                <p>Nos formations sont adaptées au public en situation de handicap</p>

                <h4>Pré requis technique</h4>
                <ul>
                <li>Navigateur web : Edge, Chrome, Firefow, Safari</li>
                <li>Système d'exploitation : Mac ou PC, smartphone</li>
                </ul>

                <hr>

                <h4>Détail formation : SketchUp pro</h4>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg>Introduction</h6></summary>
                <ul>
                <li>Le mot de l'auteur</li>
                <li>Téléchargement de SketchUp</li>
                <li>Installation</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg>L'interface</h6></summary>
                <ul>
                <li>Présentation</li>
                <li>Les barres d'outils</li>
                <li>Personnalisation des barres d'outils</li>
                <li>La palette par défaut</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg>Les préférencesdu logiciel</h6></summary>
                <ul>
                <li>Les préférences - Partie 1</li>
                <li>Les préférences - Partie 2</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg>Le modèle</h6></summary>
                <ul>
                <li>Les infos du modèle</li>
                <li>Création d'un modèle type</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> La 2D et 3D dans SketchUp</h6></summary>
                <ul>
                <li>La 2D</li>
                <li>La 3D</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les outils de dessin</h6></summary>
                <ul>
                <li>Introduction aux outils de dessin</li>
                <li>La ligne</li>
                <li>Le rectangle</li>
                <li>Le rectangle orienté</li>
                <li>Le cercle</li>
                <li>Le polygone</li>
                <li>Les Arcs</li>
                <li>Main levée</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les Outils de Caméra</h6></summary>
                <ul>
                <li>La navigation : Orbite, Panoramique, Zoom, Zoom étendu, retour de zoom</li>
                <li>La visite : positionner la caméra, pivoter, visite</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> La modélisation</h6></summary>
                <ul>
                <li>Le pousser tirer</li>
                <li>Le percement</li>
                <li>Dessiner dans l'espace 3D</li>
                <li>Le suivez-moi</li>
                <li>Le déplacement</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les outils de modification</h6></summary>
                <ul>
                <liDéplacer></li>
                <li>Copier</li>
                <li>Échelle</li>
                <li>Échelle : exercice - le crayon</li>
                <li>Décaler</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les outils de construction</h6></summary>
                <ul>
                <li>L'outil mètre</li>
                <li>L'outil mètre :mise à l'échelle</li>
                <li>Exercice :Mise à l'échelle d'un plan</li>
                <li>Les cotations</li>
                <li>Les attributs de cotation</li>
                <li>Le rapporteur</li>
                <li>L'outil texte</li>
                <li>Les Axes</li>
                <li>Le texte 3D</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> La gestion des vues</h6></summary>
                <ul>
                <li>Les vues standard</li>
                <li>Les scènes</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les sections</h6></summary>
                <ul>
                <li>Création d'un plan de section</li>
                <li>L'affichage des sections</li>
                <li>Plan de coupe et export</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les styles</h6></summary>
                <ul>
                <li>La barre d'outils Style</li>
                <li>La fenêtre Style</li>
                <li>Les arêtes</li>
                <li>Les faces</li>
                <li>Le filigrane</li>
                <li>Les paramètres de modélisation</li>
                <li>Les mélanges de style</li>
                <li>Création de style</li>
                </ul></details>


                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> La géoposition</h6></summary>
                <ul>
                <li>Géolocaliser son modèle</li>
                <li>Export vers Google Earth</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les ombres</h6></summary>
                <ul>
                <li>Paramétrage des ombres</li>
                <li>Les ombres avec les scènes</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> La sélection</h6></summary>
                <ul>
                <li>La sélection par zone</li>
                <li>La sélection par le clic</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les groupes</h6></summary>
                <ul>
                <li>Les groupes</li>
                <li>Pourquoi créer un groupe ?</li>
                <li>Effacer et masquer</li>
                <li>Le mode isolation</li>
                <li>Isolation : raccourci</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les composants</h6></summary>
                <ul>
                <li>Les composants</li>
                <li>Remplacement de composant</li>
                <li>Composants similaires</li>
                <li>Enregistrer un composant</li>
                <li>Créer une collection</li>
                <li>Recharger un composant</li>
                <li>Rendre unique</li>
                </ul></details>


                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les composants dynamiques</h6></summary>
                <ul>
                <li>A quoi ça sert ?</li>
                <li>La barre d'outils composants dynamiques</li>
                <li>La fonction Onclick</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> La fenêtre structure</h6></summary>
                <ul>
                <li>Découverte</li>
                <li>Filtres et Options</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les balises</h6></summary>
                <ul>
                <li>Découverte</li>
                <li>Les options (couleurs, types de traits, purger)</li>
                <li>Les balises et les scènes</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> 3D Warehouse</h6></summary>
                <ul>
                <li>Télécharger un composant</li>
                <li>Outil correcteur localisé</li>
                <li>Partager un objet en ligne</li>
                <li>Gérer son profil</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les Extensions</h6></summary>
                <ul>
                <li>Les extensions</li>
                <li>Installation</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Le bac à sable</h6></summary>
                <ul>
                <li>Créer un terrain 3D</li>
                <li>L'outil tamponner</li>
                <li>L'outil projeter</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> La barre d'outils solide</h6></summary>
                <ul>
                <li>Opérations entre solides</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les matières</h6></summary>
                <ul>
                <li>Appliquer une matière</li>
                <li>Le mode édition</li>
                <li>Modifier la position</li>
                <li>Importer une matière</li>
                <li>Rendre la texture unique</li>
                <li>Créer sa propre texture</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Adapter une photo</h6></summary>
                <ul>
                <li>Importation et paramétrage</li>
                <li>Modélisation et projection des textures</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Exportation</h6></summary>
                <ul>
                <li>Exporter une image de type Jpeg</li>
                <li>Exporter un fichier vectoriel</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Présentation vidéo</h6></summary>
                <ul>
                <li>Mise en place des scènes</li>
                <li>Export vidéo</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Layout</h6></summary>
                <ul>
                <li>Découverte de l'interface</li>
                <li>Préparation dans SketchUp</li>
                <li>Transfert vers Layout</li>
                <li>Paramétrage Layout</li>
                <li>Gestion des échelles</li>
                <li>Gestion des pages et scènes</li>
                <li>Cotations et étiquettes de texte</li>
                <li>Cartouche et texte de présentation</li>
                <li>Export du document</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Exercices</h6></summary>
                <ul>
                <li>Les primitives</li>
                <li>Cloison et plan de travail</li>
                <li>Le meuble de cuisine</li>
                <li>Murs et fenêtres</li>
                <li>La table basse</li>
                <li>Le tiroir</li>
                <li>Le Rubik's Cube</li>
                <li>L'escalier</li>
                <li>La maison - Partie 1</li>
                <li>La maison - Partie 2</li>
                <li>La maison - Partie 3</li>
                <li>La maison - Partie 4</li>
                <li>La maison - Partie 5</li>
                <li>La maison - Partie 6</li>
                <li>La maison - Partie 7</li>
                <li>Le mot de la fin</li>
                </ul></details>
                </div>`
            },
            {
                "id": "1de42b12-5ea6-42b8-8967-5c60260046b6",
                "title": "Word",
                "image": "word_1.png",
                "pdfFile": "/formations/bureautique/word.pdf",
                "body": `<div className='blog-main-content'>
                <p>Grâce à Word vous pourrez rédiger vos courriers,créer et partager du contenu d'aspect professionnel grâce à un jeu complet d'outils d'écriture et à l'interface simple à utiliser.</p>
                
                <h4 className='title'>Vous pourrez par la suite :</h4>
                <ul><li>Maîtriser la rédaction d'un courrier professionnel ou personnel</li>
                <li>Intégrer des images</li>
                <li>Communiquer avec les diagrammes SmartArt et les nouveaux outils de création graphiques</li>
                <li>Utiliser le panneau de révision à trois volets pour comparer et fusionner des documents</li>
                </ul>
                
                <h4 className='title'>Objectifs :</h4>
                <p>Avec cette formation Photoshop, vous pourrez apprendre et maîtriser les outils de de Photoshop, réaliser des photomontages, du détourage d'images et des créations de visuels graphiques.</p>
                
                <h4 className='title'>Certification :</h4>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I-formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre 2018, Décret n° 2019-565 du 6 juin 2019).</p>
                
                <h4 className='title'>Financement :</h4>
                <p>Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>
                
                <hr>
                
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/word_2.png' alt="word picture" />
                <p>Se perfectionner sur l'outil de traitement de texte le plus utilisé au monde.</p>
                </div>

                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/word_3.png' alt="word picture" />
                <p>Consacrer plus de temps à la rédaction et moins de temps à la mise en forme.</p>
                </div>
                
                <hr>
                
                <h4>Programme de formation</h4>
                
                <h6>Objectifs pédagogiques</h6>
                <p>Avec cette formation Word 2019®, vous saurez en 38 leçons créer des documents Word simples avec la mise en forme des caractères et paragraphes, réaliser la mise en page avec la numérotation des pages, les en-têtes et pieds de page et gérer l'impression de vos documents.</p>

                <h6>Système d'évaluation</h6>
                <p>Questionnaires des acquis en amont puis en fin de formation.</p>

                <h6>Accessibilité</h6>
                <p>Nos formations sont adaptées au public en situation de handicap</p>

                <h6>Pré requis technique</h6>
                <ul>
                <li>Navigateur web : Edge, Chrome, Firefow, Safari</li>
                <li>Système d'exploitation : Mac ou PC, smartphone</li>
                </ul>

                <hr>

                <h4>Détail formation : Word 2019</h4>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Pour commencer</h6></summary>
                <ul>
                <li>Découvrir Word</li>
                <li>Saisir du texte et enregistrer un document</li>
                <li>Fermer et ouvrir un document</li>
                <li>Se déplacer dans un document</li>
                <li>Modes d'affichage d'un document</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Saisie et mise en forme des caractères</h6></summary>
                <ul>
                <li>Saisir du texte</li>
                <li>Sélectionner du texte</li>
                <li>Mettre en forme du texte</li>
                <li>Reproduire / annuler une mise en forme</li>
                <li>Maîtriser les options de la boîte Police</li>>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Modifier du texte</h6></summary>
                <ul>
                <li>Modifier, insérer supprimer du texte</li>
                <li>Copier et coller du texte</li>
                <li>Couper et déplacer du texte</li>
                <li>Le Presse-papiers Office</li>
                <li>Annuler, rétablir et répéter des modifications</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Mise en forme de paragraphes</h6></summary>
                <ul>
                <li>Modifier l'alignement d'un paragraphe</li>
                <li>Effectuer des retraits de paragraphes</li>
                <li>Modifier l'interligne et l'espacement des textes</li>
                <li>Empêcher une rupture entre des lignes ou des paragraphes</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Bordures Et des trames</h6></summary>
                <ul>
                <li>Ajouter et personnaliser la bordure</li>
                <li>Appliquer une trame de fond</li>
                <li>Ajouter une bordure aux pages</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Puces et numéros</h6></summary>
                <ul>
                <li>Créer une liste à puces ou numérotée</li>
                <li>Modifier une liste à puces ou numérotée</li>
                <li>Utiliser des paragraphes et ajuster les retraits dans une liste</li>
                <li>Utiliser une liste à plusieurs niveaux</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Symboles, caractères spéciaux, lettrines</h6></summary>
                <ul>
                <li>Insérer un symbole ou un caractère spécial</li>
                <li>Ajouter une lettrine</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Mise en page</h6></summary>
                <ul>
                <li>Paramétrer les marges et l'orientation des pagesr</li>
                <li>Insérer un saut de page</li>
                <li>Créer et mettre en page des sections</li>
                <li>Numéroter les pages</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> En-tête, pied de page et filigrane</h6></summary>
                <ul>
                <li>Créer un en-tête et un pied de page</li>
                <li>Modifier et supprimer un en-tête et un pied de page</li>
                <li>Créer, personnaliser et supprimer un filigrane</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Impression</h6></summary>
                <ul>
                <li>Imprimer un document et paramétrer l'impression</li>
                <li>Imprimer une enveloppe</li>
                <li>Imprimer des étiquettes</li>
                </ul></details>
                </div>`
            },
            {
                "id": "32e70b5f-dcd2-4738-9309-2c568924c620",
                "title": "Wordpress",
                "image": "wordpress_1.png",
                "pdfFile": "/formations/bureautique/wordpress.pdf",
                "body": `<div className='blog-main-content'>
                <p>Grâce à WordPress vous pourrez créer un site internet vous-même simplement et rapidement.</p>
                
                <h4 className='title'>Vous pourrez par la suite :</h4>
                <ul><li>Créer un site web à votre image </li>
                <li>Héberger vous-même votre contenu </li>
                </ul>
                
                <h4 className='title'>Objectifs :</h4>
                <ul><li>Apprendre et maîtriser les outils et fonctionnalités de base wordpress</li>
                <li>Réaliser des sites web aux thèmes personnalisés et des blogs en ligne</li></ul>
                
                <h4 className='title'>Certification :</h4>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I-formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre 2018, Décret n° 2019-565 du 6 juin 2019).</p>
                
                <h4 className='title'>Financement :</h4>
                <p>Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>
                
                <hr>
                
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/wordpress_2.png' alt="wordpress picture" />
                <p>Se familiariser avec l'interface wordpress.</p>
                </div>
                
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/wordpress_3.png' alt="wordpress picture" />
                <p>Création de votre site internet de A à Z.</p>
                </div>
                
                <hr>
                
                <h4>Programme de formation</h4>
                
                <h6>Objectifs pédagogiques</h6>
                <p>Avec cette formation WordPress, vous pourrez en 16 modules apprendre et maitriser les outils et fonctionnalités de base de Wordpress, réaliser des sites web aux thèmes personnalisés et des blogs en ligne.</p>

                <h6>Système d'évaluation</h6>
                <p>Questionnaires des acquis en amont puis en fin de formation.</p>

                <h6>Accessibilité</h6>
                <p>Nos formations sont adaptées au public en situation de handicap</p>

                <h6>Pré requis technique</h6>
                <ul>
                <li>Navigateur web : Edge, Chrome, Firefow, Safari</li>
                <li>Système d'exploitation : Mac ou PC, smartphone</li>
                </ul>

                <hr>

                <h4>Détail formation : WordPress</h4>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Acheter Son hébergement et son nom de domaine</h6></summary>
                <ul>
                <li>Aller sur OVH</li>
                <li>Passer la commande OVH</li>
                <li>Finalisation de la commande</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> L'interface de WordPress</h6></summary>
                <ul>
                <li>Connexion à l'interface</li>
                <li>Réglage général</li>
                <li>Réglage de la lecture/écriture</li>
                <li>Réglage des discussions</li>
                <li>Réglage des Médias</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Apparence - La gestion du thème</h6></summary>
                <ul>
                <li>Présentation Mettre à jour WordPress</li>
                <li>Les différents thèmes</li>
                <li>Choix du thèmes</li>
                <li>Les paramètres de personnalisation : le titre</li>
                <li>Titre et logo du site</li>
                <li>Changer le Header</li>
                <li>Fin des paramètres de WordPress</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Créer des PAGES avec GUTENBERG</h6></summary>
                <ul>
                <li>Télécharger Gutenberg (si besoin)</li>
                <li>Présentationde Gutenberg</li>
                <li>Commencer à écrire un texte</li>
                <li>Ecrire du texte et le modifier</li>
                <li>Insérer une image</li>
                <li>Insérer une galerie</li>
                <li>Insérer une bannière</li>
                <li>Aperçu de notre page</li>
                <li>Média et texte</li>
                <li>Insérer une vidéo</li>
                <li>Créer un bouton</li>
                <li>Mettre la bonne destination à notre bouton</li>
                <li>Comment faire des colonnes</li>
                <li>Séparateur et espacement</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> ATELIER - Créer une page avec Gutenberg</h6></summary>
                <ul>
                <li>Présentation de la page à réaliser</li>
                <li>Paramétrer le thème</li>
                <li>Créer les premièrescolonnes</li>
                <li>Création du 1er bloc</li>
                <li>Paramétrage du 1er bloc</li>
                <li>Création du 2e bloc</li>
                <li>Mise en forme et création du bouton</li>
                <li>Création de la bannière</li>
                <li>Création du dernier bloc</li>
                <li>Personnaliser sa typo avec du CSS</li>
                <li>Copier les codes CSS</li>
                <li>Trouver une autre typo</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Pages</h6></summary>
                <ul>
                <li>Modifier une page</li>
                <li>Présentation rapide de l'interface</li>
                <li>Installer Elementor</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Ajouterdes PAGES avec ELEMENTOR</h6></summary>
                <ul>
                <li>des options dans l'onglet</li>
                <li>Options de réglages</li>
                <li>Les derniers réglages</li>
                <li>Création de la 1re section</li>
                <li>Insérer une image et faire les réglages</li>
                <li>Ajout de titre et texte</li>
                <li>Créer une autre section</li>
                <li>Les boutons</li>
                <li>Insérer une vidéo</li>
                <li>Séparateur espaceur</li>
                <li>Intégrer Google map</li>
                <li>Ajouter des icônes</li>
                <li>Insérer une boîte image</li>
                <li>La boîte d'icônes</li>
                <li>La notation</li>
                <li>Galerie photos</li>
                <li>Carrousel d'images</li>
                <li>Liste d'icônes</li>
                <li>Le compteur</li>
                <li>La barre de progression</li>
                <li>Témoignage</li>
                <li>Onglet accordéon - Va et vient</li>
                <li>Réseaux sociaux</li>
                <li>Créer une alerte</li>
                <li>Aperçu des derniers outils</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les articles</h6></summary>
                <ul>
                <li>Différence entre pages et articles</li>
                <li>Catégories des articles</li>
                <li>Les étiquettes</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Avançons ensemble</h6></summary>
                <ul>
                <li>Les médias</li>
                <li>Les commentaires</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les extensions- Plugins</h6></summary>
                <ul>
                <li>Présentation</li>
                <li>Extension - Formulaire de contact</li>
                <li>Extension SEO</li>
                <li>WooCommerce - Introduction</li>
                <li>Créer une fiche produit</li>
                <li>Introduction des autres fonctionsde WooCommerce</li>
                <li>WooCommerce - Dashboard - Commandes - Coupons</li>
                <li>WooCommerce - Les réglages de la boutique</li>
                <li>WooCommerce - État et extensions</li>
                <li>WooCommerce - Analytics</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les menus de votre site</h6></summary>
                <ul>
                <li>Ajouter des éléments au menu</li>
                <li>Gérer l'emplacement du menu</li>
                <li>Optimiser le menu</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Création d'un site - Portfolio</h6></summary>
                <ul>
                <li>Présentation De l'atelier</li>
                <li>Installation Et recherche du thème</li>
                <li>Personnalisation De l'en-tête</li>
                <li>Création du 1e article</li>
                <li>Création du 2e article</li>
                <li>Quelques paramètres de propriétésdu thèmes</li>
                <li>Permalien et changement du nom de l'auteur</li>
                <li>Nouvel article et nouvelle catégorie</li>
                <li>Menu - Insérer les catégories au menu</li>
                <li>Création de la page Qui suis-je</li>
                <li>Rajouter une page au menu</li>
                <li>Générer un formulaire de contact simple</li>
                <li>Intégrer le formulaire de contact</li>
                <li>Contactez-moi - Astuce et bouton</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Création du site Business pas à pas (suite)</h6></summary>
                <ul>
                <li>Présentation</li>
                <li>Télécharger le thème</li>
                <li>Personnaliser l'identité du site</li>
                <li>Modifier les couleurs de votre thème</li>
                <li>Présentationdu menu à partir du thème</li>
                <li>Création des pages vierges</li>
                <li>Créer le menu</li>
                <li>Page d'accue</li>
                <li>Paramétrer Elemento</li>
                <li>Générer le titre</li>
                <li>Importation des images</li>
                <li>Animer les images</li>
                <li>Titre et sous-titre</li>
                <li>Boite d'image</li>
                <li>Boite d'image et éditeur de texte</li>
                <li>Positionner les widgets</li>
                <li>Modifier les sections</li>
                <li>Création du nouveau titre</li>
                <li>Création de la nouvelle partie Photoshop</li>
                <li>Modifier la typo via l'éditeur de texte</li>
                <li>Créer les ancres</li>
                <li>Relier le menu aux ancres</li>
                <li>Pages Nos formateurs</li>
                <li>Titre au-dessus des compteurs</li>
                <li>Aligner les titres des compteurs</li>
                <li>Ajouter les compteurs</li>
                <li>Autre possibilité pour créer un compteur</li>
                <li>Deux extensions pour Elementor</li>
                <li>Explication de IMAGE HOVER</li>
                <li>Mettre à jour nos images avec IMAGE HOVER</li>
                <li>Créer les liens des images</li>
                <li>Créer des liens internes</li>
                <li>Formulaire de contact</li>
                <li>Présentation page ADOBE</li>
                <li>Couleur et ombre 1re section</li>
                <li>Image et texte</li>
                <li>Ajouter le séparateur Rouge</li>
                <li>Création de la partie de droite de la colonne</li>
                <li>Ajouter des images dans la section 2</li>
                <li>Le bouton de la section 2</li>
                <li>Finalisation</li>
                <li>Dernier tableau</li>
                <li>Conclusion</li>
                </ul></details>


                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Création du site E-commerce - Myshoes (suite)</h6></summary>
                <ul>
                <li>Mise en place du thème et WooCommerce - Télécharger le thème</li>
                <li>Mise en place du thème et WooCommerce - Configurer le titre et favicon</li>
                <li>Mise en place du thème et WooCommerce - Installationde WooCommerce</li>
                <li>Les produits - Configurer un produit simple</li>
                <li>Les produits - Produit externe et affilié</li>
                <li>Les catégories / Widgets / Thème - Gestion des catégories</li>
                <li>Les catégories / Widgets / Thème - Gérer le widget de la sidebar</li>
                <li>Les catégories / Widgets / Thème - Paramètre du thème WooCommerce</li>
                <li>La page d'accueil - Personnalisation de la page</li>
                <li>La page d'accueil - Création</li>
                <li>La page d'accueil - Étude de la concurrence</li>
                <li>La page d'accueil - Présentationde la page</li>
                <li>La page d'accueil - Création de la 1re bannière</li>
                <li>La page d'accueil - Création de la 2e bannière</li>
                <li>La page d'accueil - Ajout des séparateurs et de la citation</li>
                <li>La page d'accueil - Ajouter les nouveautés avec WooCommerce</li>
                <li>La page d'accueil - Finalisation de la page d'accueil</li>
                <li>Mega Menu - Installationde l'extension</li>
                <li>Mega Menu - Présentation générale</li>
                <li>Mega Menu - Plus de détail des paramétrages</li>
                <li>Mega Menu - Ajouter le contenu du menu</li>
                <li>Mega Menu - Personnaliser le Mega Menu</li>
                <li>Mega Menu - Dernière paramètre du Mega Menu</li>
                <li>Le footer - Créer son footer</li>
                <li>Faire un pop up - Télécharger l'extension</li>
                <li>Faire un pop up - Paramétrage du Pop up</li>
                <li>Faire un pop up - Les cookies du Pop up</li>
                <li>Administration - Présentation de l'interface Admin WooCommerce</li>
                <li>Administration- Partie Analytics</li>
                <li>Administration- Paramétrer Paypal</li>
                <li>Administration- Générer un processus de Vente</li>
                <li>Conclusion</li>
                </ul></details>
                

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Création de site internet - Style Business</h6></summary>
                <ul>
                <li>Présentation</li>
                <li>Télécharger le thème</li>
                <li>Décryptage du thème OnePress</li>
                <li>Mettre le site en maintenance</li>
                <li>Configurer la page de maintenance</li>
                <li>Commençons la création</li>
                <li>Créer la page d'accueil</li>
                <li>Section HERO</li>
                <li>Section FONCTIONNALITES</li>
                <li>Section A PROPOS</li>
                <li>Section SERVICES</li>
                <li>Section VIDEO LIGHTBOX</li>
                <li>Section GALERIE</li>
                <li>Section COMPTEUR</li>
                <li>Section EQUIPE</li>
                <li>Section NOUVEAUTE</li>
                <li>Section CONTACT</li>
                <li>Identité du site</li>
                <li>Le menu</li>
                <li>Ajouter un flux Instagram</li>
                <li>Pied de page</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Référencement SEO</h6></summary>
                <ul>
                <li>Yoast SEO - Le télécharger</li>
                <li>Comment présenter les recherches Google</li>
                <li>Assistant de configuration</li>
                <li>Les réglages de Yoast SEO</li>
                <li>Derniersréglages</li>
                <li>Réglage de la page</li>
                <li>Réglage des réseaux sociaux</li>
                <li>Optimiser une autre page</li>
                </ul></details>
                </div>`
            }
        ]
    },
    {
        "title": "Développer votre entreprise",
        "slogan": "Allez plus loin dans vos projets",
        "items": [
            {
                "id": "9ceb7c4d-7153-4070-b097-c91b639868e2",
                "title": "Bilan de compétence",
                "image": "bilandecompetences_1.png",
                "pdfFile": "/formations/developper_votre_entreprise/bilan_de_competence.pdf",
                "body": `<div className='blog-main-content'>
                <p>Grâce à ce bilan de compétences vous pourrez analyser votre profil et vos besoins</p>
                
                <h4 className='title'>Vous pourrez par la suite :</h4>
                <ul><li>Valider et élaborer votre projet professionnel</li>
                <li>Évaluer vos connaissances</li>
                </ul>
                
                <h4 className='title'>Objectifs :</h4>
                <ul><li>Faire le point sur votre parcours, votre personnalité, vos atouts et intérêts</li>
                <li>Créer un projet professionnel réaliste et motivant</li></ul>
                
                <h4 className='title'>Certification :</h4>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I-formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre 2018, Décret n° 2019-565 du 6 juin 2019).</p>
                
                <h4 className='title'>Financement :</h4>
                <p>Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>
                
                <hr>
                
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/bilandecompetences_2.png' alt="bilan de competences picture" />
                <p>Réaliser un Bilan de compétences afin de comprendre et mettre en avant vos atouts.</p>
                </div>
                
                <hr>
                
                <h4>Programme de formation</h4>

                <ul>
                <li>Phase préliminaire</li>
                <li>Phase d'investigation </li>
                <li>Élaboration du portefeuille de compétences</li>
                <li>Élaboration du ou des projets professionnels</li>
                <li>Phase de conclusion remise de synthèse</li>
                <li>Faire son bilan professionnel. Décrire ses réalisations professionnelles et extra-professionnelles. ...</li>
                <li>Faire son bilan personnel. Motivations au travail. ...</li>
                <li>Construire son projet professionnel. Identifier les opportunités de son environnement. ...</li>
                <li>Se préparer à changer. Connaître ses forces et ses résistances face au changement. ...</li>
                <li>Bâtir sa stratégie d'évolution. ...</li>
                <li>Analyser ses propres compétences professionnelles et personnelles</li>
                <li>Analyser ses aptitudes et ses motivations notamment professionnelles,</li>
                <li>Avoir le recul suffisant pour construire un projet d'évolution professionnelle</li>
                </ul>

                </div>`
            },
            {
                "id": "c33345fc-76a1-4781-9717-f3b6bbe2fbe3",
                "title": "Creation d'entreprise",
                "image": "creationentreprise_1.png",
                "pdfFile": "/formations/developper_votre_entreprise/creation_dentreprise.pdf",
                "body": 
                `<div className='blog-main-content'>
                <p>Grâce à cette formation vous pourrez créer votre entreprise afin de devenir votre propre chef et d'organiser votre travail comme bon vous semble</p>
                
                <h4 className='title'>Vous pourrez par la suite :</h4>
                <ul>
                    <li>Développer votre entreprise</li>
                    <li>Promouvoir sa création</li>
                    <li>Cibler votre clientèle</li>
                </ul>
                
                <h4 className='title'>Objectifs :</h4>
                <ul>
                    <li>Appréhender la création d'entreprise</li>
                    <li>Créer un business plan</li>
                    <li>Choisir le statut le plus adapté à votre situation</li>
                    <li>Mettre en place des outils de suivi de votre activité</li>
                </ul>
                
                <h4 className='title'>Certification :</h4>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I-formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre 2018, Décret n°2019-565 du 6 juin 2019).</p>
                
                <h4 className='title'>Financement :</h4>
                <p>Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>
                
                <hr>
                
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/creationentreprise_2.png' alt="creation entreprise picture" />
                <p>Créer son business plan.</p>
                </div>
                
                <hr>
                
                <h4>Programme</h4>

                <h6>Système d'évaluation</h6>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I-formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre 2018, Décret n°2019-565 du 6 juin 2019).</p>

                <h6>Objectifs pédagogiques</h6>
                <p>Questionnaires des acquis en amont puis en fin de formation</p>

                <h6>Accessibilité</h6>
                <p>Nos formations sont adaptées au public en situation de handicap</p>

                <h6>Pré requis technique</h6>
                <ul>
                    <li>Navigateur web : Edge, Chrome, Firefox, Safari</li>
                    <li>Système d'exploitation : Mac ou PC, smartphone</li>
                </ul></details>

                <h4>Détail de la formation</h4>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Introduction</h6></summary>
                <ul>
                    <li>Introduction</li>
                    <li>Choisir une idée</li>
                    <li> Les services indispensables</li>
                    <li>5 bonnes raisons de passer par un incubateur d'entreprise</li>
                </ul></details>
                
                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Comment créer une entreprise</h6></summary>
                <ul>
                    <li>Comment créer une entreprise - Partie 1</li>
                    <li>Comment créer une entreprise - Partie 2</li>
                    <li>Comment créer une entreprise - Partie 3</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> L'état d'esprit et les compétences de l'entrepreneur</h6></summary>
                <ul>
                    <li>L'état d'esprit de l'entrepreneur</li>
                    <li>L'état d'esprit de l'entrepreneur</li>
                    <li>La compétence commerciale</li>
                    <li>Qu'est-ce qu'un bon minds et d'entrepreneur ?</li>
                    <li>5 astuces pour développer un bon minds et d'entrepreneur(e)</li>
                    <li>
                        <p>Clés pour travailler son minds et booster son résultat</p>
                        <ul>
                            <li>Les difficultés les plus couramment rencontrées par les entrepreneur(e)s et comment les surmonter</li>
                            <li>4 difficultés rencontrés par les entrepreneurs qui se lancent</li>
                        </ul>
                    </li>
                    <li>Le réseautage, qu'est ce que c'est et comment bien faire en 3 clés essentielles</li>
                    <li>L'importance du réseautage dans l'entrepreneuriat</li>  
                    <li>Comment réseauter pour développer votre activité</li>  
                    <li>Comment vaincre la solitude de l'entrepreneur(e)</li>  
                    <li>8 grandes erreurs à éviter pour les entrepreneur(e)s</li>  
                    <li>La résilience, cette fabuleuse capacité à rebondir de l'entrepreneur</li>  
                    <li>
                        <p>Peut-on créer et gérer autant d'entreprises que l'on veut</p>
                        <ul>
                            <li>Femmes entrepreneures quelles aides pour l'entrepreneuriat au féminin</li>
                        </ul>
                    </li>  
                    <li>Comment vaincre la solitude de l'entrepreneur(e)</li>                      
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> La rentabilité pour l'entrepreneur</h6></summary>
                <ul>
                    <li>Le seuil de rentabilité</li>
                    <li>Le financement pro</li>
                    <li>La responsabilité pénale du dirigeant</li>
                    <li>Les indicateurs financiers du compte de résultat</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Le SWOT, un outil puissant de prise de décision</h6></summary>
                <ul>
                    <li>Introduction au SWOT (ou MOFF)</li>
                    <li>SWOT - Étude de cas - Partie 1</li>
                    <li>SWOT - Étude de cas - Partie 2</li>
                    <li>SWOT - Étude de cas - Partie 3</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les 4 P - Positionner votre offre sur le marché</h6></summary>
                <ul>
                    <li>Introduction aux 4P</li>
                    <li>Schéma du positionnement de produit</li>
                    <li>Le cycle de vie d'un produit</li>
                    <li>Approfondissement des positionnements de produits</li>
                    <li>Étude de cas : Nespresso</li>
                    <li>Conclusiondu 4P</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Le bilan</h6></summary>
                <ul>
                    <li>Introduction</li>
                    <li>L'actif du bilan</li>
                    <li>Le Passif du Bilan</li>
                    <li>Le compte de résultat</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Étude de cas d'un Bilan</h6></summary>
                <ul>
                    <li>Étude de bilan 1/3</li>
                    <li>Étude de bilan 2/3</li>
                    <li>Étude de bilan 3/3</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Le plan de trésorerie</h6></summary>
                <ul>
                    <li>Le plan de trésorerie</li>
                    <li>Le chiffre d'affaires du plan de trésorerie</li>
                    <li>Les années N+1 et N+2 du plan de trésorerie</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Le business plan</h6></summary>
                <ul>
                    <li>Le business plan - Partie 1</li>
                    <li>Le business plan - Partie 2</li>
                    <li>Comment savoir si son idée de business est bonne et réaliste ?</li>
                    <li>Télécharger la trame - Partie 1</li>
                    <li>Télécharger la trame - Partie 2</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Gestion simplifiée de la comptabilité</h6></summary>
                <ul>
                    <li>Gestion simplifiée de la comptabilité</li>
                    <li>La digitalisation de la comptabilité</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> L'organisation de votre temps</h6></summary>
                <ul>
                    <li>L'organisation de votre temps</li>
                    <li>L'organisation via l'agenda</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Le management : ce qu'il faut savoir pour démarrer</h6></summary>
                <ul>
                    <li>Déléguer : passer du faire au faire faire</li>
                    <li>Comment apprendre à déléguer en tant que chef(fe) d'entreprise ?</li>
                    <li>Les 6 étapes de la délégation</li>
                    <li>Prendre des singes ou déléguer</li>
                    <li>Les raisons pour lesquelles les bons employés démissionnent</li>
                    <li>Les bonnes pratiques pour retenir ses bons employés</li>
                    <li>Donner un cadre clair à ses collaborateurs pour libérer l'autonomie</li>
                    <li>Recruter du personnel</li>
                    <li>Rupture de contrat de travail : les règles à connaitre</li>
                    <li>Dans quels cas l'employeur peut-il demander la rupture du contrat de travail ?</li>
                    <li>Fin du contrat de travail : quelles sont vos obligations administratives en tant que chef d'entreprise ?</li>
                    <li>Comment devenir un bon manager en étant entrepreneur ?</li>
                    <li>Le licenciement, une procédure en 3 temps</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les outils de l'entrepreneur</h6></summary>
                <ul>
                    <li>Les outils de création - Partie 1</li>
                    <li>Les outils de création - Partie 2</li>
                    <li>Les outils de création - Partie 3</li>
                    <li>Développer son entreprise grâce à Linkedin</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Le module juridique</h6></summary>
                <ul>
                    <li>Choisir le statut juridique adapté à votre projet si vous êtes seul</li>
                    <li>Choisir le statut juridique adapté à votre projet si vous êtes à plusieurs</li>
                    <li>Les différents apports dans le capital d'une entreprise apport en numéraire</li>
                    <li>Les différents apports dans le capital d'une entreprise apport en nature</li>
                    <li>Les différents apports dans le capital d'une entreprise apport en industrie</li>
                    <li>Société en formation, que peut on faire et ne pas faire</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Le module financier</h6></summary>
                <ul>
                    <li>Trouver des financements</li>
                    <li>Comment calculer son seuil de rentabilité et son point mort</li>
                    <li>Comment trouver des investisseurs pour financer sa création d'entreprise</li>
                    <li>Le crowdfunding</li>
                    <li>Pourquoi et comment faire une levée de fonds pour votre startup</li>
                    <li>Les principales erreurs à éviter lors d'une levée de fonds</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Quiz final</h6></summary>
                <ul>
                    <li>Quiz - Création d'entreprise</li>
                </ul></details>

                </div>`
            },
            {
                "id": "9a99f1f4-84f5-4471-82cc-322e36d62e67",
                "title": "Décoration d'intérieur",
                "image": "decorationinterieur_1.png",
                "pdfFile": "/formations/developper_votre_entreprise/decoration_dinterieur.pdf",
                "body": `<div className='blog-main-content'>
                <p>Grâce à la décoration d'intérieur  vous pourrez illuminer vos lieux et en faire un endroit où il fait bon vivre.</p>
                
                <h4 className='title'>Vous pourrez par la suite :</h4>
                <ul>
                    <li>Valoriser votre intérieure</li>
                    <li>Améliorer votre qualité de vie</li>
                    <li>Trouver un certain équilibre intérieur</li>
                </ul>
                
                <h4 className='title'>Objectifs :</h4>
                <p>Avec cette formation vous serez en capacité d'apprendre les bases de la décoration d'intérieur.</p>
                
                <h4 className='title'>Certification :</h4>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I-formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre 2018, Décret n°2019-565 du 6 juin 2019).</p>
                
                <h4 className='title'>Financement :</h4>
                <p>Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>
                
                <hr>
                
                <div className="text-center rounded" className="thumbnail block-alignwide">
                    <p>La décoration est la seule chose qui puisse donner à un habitat une âme et une touche unique.</p>
                    <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/decorationinterieur_2.png' alt="photoshop picture" />
                </div>
                <div className="text-center rounded" className="thumbnail block-alignwide">
                    <p>Orner son intérieur d'une déco originale et savoir utiliser des logiciels de CAO 3D</p>
                    <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/decorationinterieur_3.png' alt="decoration intérieur" />
                </div>
                
                <hr>
                
                <h4>Programme</h4>

                <h6>Système d'évaluation</h6>
                <p>Questionnaires des acquis en amont puis en fin de formation</p>

                <h6>Objectifs pédagogiques</h6>
                <p>Orner son intérieur d'une déco originale et savoir utiliser des logiciels de CAO 3D</p>

                <h6>Accessibilité</h6>
                <p>Nos formations sont adaptées au public en situation de handicap</p>

                <h6>Pré requis technique</h6>
                <ul>
                    <li>Navigateur web : Edge, Chrome, Firefox, Safari</li>
                    <li>Système d'exploitation : Mac ou PC</li>
                </ul>

                <h4>Détail formation : Décoration d'intérieur</h4>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Le langage visuel du design</h6></summary>
                <ul>
                    <li>Introduction</li>
                    <li>La théorie du design</li>
                    <li>Le design d'espace</li>
                    <li>Les principes du design</li>
                    <li>Les fondamentaux du design d'intérieur</li>
                    <li>Les éléments du design</li>
                    <li>Quiz</li>
                </ul></details>
                
                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> L'histoire de l'architecture et des styles</h6></summary>
                <ul>
                    <li>Le néolithique</li>
                    <li>L'architecture mésopotamienne</li>
                    <li>L'Égypte antique</li>
                    <li>Les temples</li>
                    <li>La décoration et les fresques murales/li>
                    <li>Le statuaire et les arts décoratifs</li>
                    <li>La Grèce antique</li>
                    <li>L'architecture romaine</li>
                    <li>Les temples</li>
                    <li>L'art bizantin</li>
                    <li>L'architecture mauresque</li>
                    <li>L'architecture gothique</li>
                    <li>La renaissance</li>
                    <li>Le maniérisme</li>
                    <li>Le Baroque 1590-1725</li>
                    <li>Le style Rococo</li>
                    <li>Les styles néo-classiques</li>
                    <li>Le style victorien</li>
                    <li>L'époque édouardienne</li>
                    <li>Le mouvement «Arts And Crafts »</li>
                    <li>L'art nouveau</li>
                    <li>L'art déco</li>
                    <li>Le modernisme</li>
                    <li>Le Bauhaus</li>
                    <li>Le post-modernisme</li>
                    <li>Le minimalisme</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les styles de décorations d'intérieur</h6></summary>
                <ul>
                    <li>Le style Africain</li>  
                    <li>Le style Américain</li> 
                    <li>Le style Art déco</li> 
                    <li>Le style oriental</li> 
                    <li>Le style asiatique </li> 
                    <li>Le style anglais</li>
                    <li>Le style flamans</li> 
                    <li>Le style Hampton</li> 
                    <li>Le style industriel</li> 
                    <li>Le style italien</li> 
                    <li>Le style minimaliste</li> 
                    <li>Le style rétro</li> 
                    <li>Le style rustique</li> 
                    <li>Le style scandinave</li>
                    <li>Le style Shabby chic</li> 
                    <li>Le style espagnol</li> 
                    <li>Le style toscan</li> 
                    <li>Quiz</li>                
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Aménagement de l'espace</h6></summary>
                <ul>
                    <li>Introduction</li>
                    <li>Les plans</li>
                    <li>Aménager un salon</li>
                    <li>Aménager une cuisine</li>
                    <li>Aménager une chambre</li>
                    <li>Aménager une salle de bain</li>
                    <li>Aménager une entrée</li>
                    <li>Aménager une buanderie</li>
                    <li>Aménager une bureau</li>
                    <li>Quiz</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> L'éclairage</h6></summary>
                <ul>
                    <li>La lumière, approche scientifique</li>
                    <li>Les sources de la lumière</li>
                    <li>L'éclairage en décoration d'intérieur</li>
                    <li>Le lighting design</li>
                    <li>Le plan d'éclairage</li>
                    <li>Quiz</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> La couleur</h6></summary>
                <ul>
                    <li>Introduction</li>
                    <li>La couleur et ses applications</li>
                    <li>La théorie de la couleur</li>
                    <li>Les contrastes et les harmonies- Partie 1</li>
                    <li>Les contrastes et les harmonies- Partie 2</li>
                    <li>Les couleurs en décoration d'intérieur - Partie 1</li>
                    <li>Les couleurs en décoration d'intérieur - Partie 2</li>
                    <li>Psychologie et couleurs - Partie 1</li>
                    <li>Psychologie et couleurs - Partie 2</li>
                    <li>Quiz</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les finitions intérieures</h6></summary>
                <ul>
                    <li>Introduction</li>
                    <li>Les plafonds -Partie 1</li>
                    <li>Les plafonds -Partie 2</li>
                    <li>Les cloisons - Partie 1</li>
                    <li>Les cloisons - Partie 2</li>
                    <li>Les cloisons - Partie 3</li>
                    <li>Les cloisons - Partie 4</li>
                    <li>Les cloisons - Partie 5</li>
                    <li>Les revêtements de sol - Partie 1</li>
                    <li>Les revêtements de sol - Partie 2</li>
                    <li>Les finitions d'embelissement</li>
                    <li>Quiz</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les textiles en décoration d'intérieur</h6></summary>
                <ul>
                    <li>Introduction</li>
                    <li>Les matières</li>
                    <li>Les textiles dans la maison</li>
                    <li>Les textiles dans la maison</li>
                    <li>Les textiles dans la maison</li>
                    <li>Le mix & match</li>
                    <li>Quiz</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> L'histoire du mobilier</h6></summary>
                <ul>
                    <li>Introduction</li>
                    <li>Le mobilier au temps des civilisations antiques</li>
                    <li>Le mobilier médiéval</li>
                    <li>La Renaissance</li>
                    <li>Le style Louis XIV</li>
                    <li>Le mobilier du XVIIIème siècle</li>
                    <li>Le mobilier du XIXème siècle</li>
                    <li>L'Art Nouveau</li>
                    <li>L'Art Déco et le Modernisme</li>
                    <li>Le mobilier d'après-guerre</li>
                    <li>Quiz</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> La relation client dans le projet de décoration</h6></summary>
                <ul>
                    <li>Introduction</li>
                    <li>Le métier de décorateur d'intérieur</li>
                    <li>La gestion d'un chantier</li>
                    <li>La panoplie du décorateur</li>
                    <li>Quiz</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> La communication visuelle en décoration d'intérieur</h6></summary>
                <ul>
                    <li>Introduction</li>
                    <li>Les planches déco</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> L'organisation de votre temps</h6></summary>
                <ul>
                    <li>L'organisation de votre temps</li>
                    <li>L'organisation via l'agenda</li>
                    <li>Les dessins en décoration d'intérieur</li>
                    <li>Les visuels 3D</li>
                    <li>Quiz</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Le développement durable appliqué à l'architecture</h6></summary>
                <ul>
                    <li>Introduction</li>
                    <li>Le développement durable</li>
                    <li>La réglementation technique ou l'approche environnementale de l'urbanisme</li>
                    <li>La démarche « haute qualité environnementale »</li>
                    <li>Les matériaux de construction bioclimatique</li>
                    <li>Les énergies renouvelables: le bois et l'eau</li>
                    <li>Quiz</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Sketchup & décoration d'intérieur</h6></summary>
                <ul>
                    <li>Introduction</li>
                    <li>Préparation du fichier</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Création du plan</h6></summary>
                <ul>
                    <li>Importation du relevé de cotes</li>
                    <li>Mise à l'échelle du relevé de cotes</li>
                    <li>Création des ouvertures (portes, fenêtres)</li>
                    <li>Création des portes fenêtres</li>
                    <li>Portes et fenêtres</li>
                    <li>Les cotations</li>
                    <li>Importation dans Layout</li>
                    <li>Mise en page dans Layout</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Modélisation de l'existant</h6></summary>
                <ul>
                    <li>Préparation du fichier 3D</li>
                    <li>Configuration du plan de base</li>
                    <li>Création du mur</li>
                    <li>Murs et sol</li>
                    <li>Portes fenêtres et plafond</li>
                    <li>Finalisation et préparation pour l'agencement</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Travailler avec les textures</h6></summary>
                <ul>
                    <li>Introduction aux matières</li>
                    <li>Importation d'images</li>
                    <li>Recherche de sources sur internet</li>
                    <li>Gestion des images en mode texture</li>
                    <li>Calepinage pour création de motifs</li>
                    <li>Création de motifs avec Photoshop</li>
                    <li>Importer une référence de peinture</li>
                    <li>Importer un nuancier de peinture</li>
                    <li>Récupération de la couleur d'une photo d'intérieur</li>
                </ul></details>
                
                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Gestion des objets</h6></summary>
                <ul>
                    <li>Introduction</li>
                    <li>Recherche d'objets et téléchargement</li>
                    <li>Connexion à 3D Warehouse</li>
                    <li>Partager votre objet</li>
                    <li>Modification des matériaux du composant</li>
                    <li>Modification de la géométrie d'un composant</li>
                    <li>Modification de la teinte d'un canapé</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Installer des extensions</h6></summary>
                <ul>
                    <li>Présentation des extensions</li>
                    <li>Installation de la JHS POWERBAR</li>
                    <li>Téléchargement  Et installation de Twilight render</li>
                    <li>JHS POWERBAR : Déplacement d'objets</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Créer des objets</h6></summary>
                <ul>
                    <li>Création de rideau avec Extrude Lines</li>
                    <li>Création de murs et d'encadrement de porte</li>
                    <li>
                        <p>Création d'objets tubulaires</p>
                        <ul>
                            <li>Modélisation d'un meuble d'après photo - partie 1</li>
                            <li>Modélisation d'un meuble d'après photo - partie 2</li>
                        </ul>
                    </li>
                    <li>Créer des plantes avec une image</li>
                    <li>Création d'un tableau</li>
                    <li>Création des brises vue</li>
                    <li>Création de luminaires(lampe et suspension)</li>
                </ul></details>
                
                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Exercice : Agencement d'un intérieur</h6></summary>
                <ul>
                    <li>Introduction exercice déco d'intérieur</li>
                    <li>Préparation de l'exercice</li>
                    <li>Création de la cheminée - Partie 1</li>
                    <li>Création de la cheminée - Partie 2</li>
                    <li>Mise en place des canapés et du sol</li>
                    <li>Création de la table basse</li>
                    <li>Mise en place des accessoires</li>
                    <li>Mise en place des luminaires</li>
                    <li>Création de scènes et export Jpeg</li>
                </ul></details>
                
                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Style esquissé et export des coupes</h6></summary>
                <ul>
                    <li>Création de style</li>
                    <li>Export de la maquette 3D vers Layout</li>
                    <li>Créer des coupes au format Dwg</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Rendu Photo réaliste avec Twilight Render</h6></summary>
                <ul>
                    <li>Introduction au photo réalisme</li>
                    <li>Rendu progressif partie 1</li>
                    <li>Rendu progressif partie 2</li>
                    <li>Rendu progressif partie 3</li>
                    <li>Réglage des suspensions</li>
                    <li>Réglage des projecteurs - Partie 1</li>
                    <li>Réglage des projecteurs - Partie 2</li>
                    <li>L'effet feu de cheminée</li>
                    <li>Réglage des spots sous tablette</li>
                    <li>Le mot de la fin</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Quiz<h3></summary>
                <ul>
                    <li>Quiz intermédiaires</li>
                    <li>Quiz final</li>
                </ul></details>`
            },
            {
                "id": "ae56c3f2-dba7-4b83-b691-f2f5e4bb9c82",
                "title": "Force de vente",
                "image": "forcedevente_1.png",
                "pdfFile": "/formations/developper_votre_entreprise/force_de_vente.pdf",
                "body": `<div className='blog-main-content'>
                <p>Grâce à cette formation vous pourrez en tant que vendeur, d'être capable d'argumenter et augmenter vos ventes.</p>
                
                <h4 className='title'>Vous pourrez par la suite :</h4>
                <ul>
                    <li>Utiliser des outils numériques pour augmenter ses ventes en magasin comme à distance</li>
                    <li>Utiliser des outils d'analyse connectés pour identifier sa cible et analyser son marché</li>
                </ul>
                
                <h4 className='title'>Objectifs :</h4>
                <p>Rendre le client acteur de la vente et de la promotion des produits et des services</p>
                
                <h4 className='title'>Certification :</h4>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I-formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre 2018, Décret n°2019-565 du 6 juin 2019).</p>
                
                <h4 className='title'>Financement :</h4>
                <p> Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>
                
                <hr>

                <h6>Objectifs pédagogiques</h6>
                <p>Avec cette formation vous pourrez gagner en impact lors d'un entretien client, répondre aux objections et questions des clients ainsi qu'optimiser vos ventes.</p>

                <h6>Système d'évaluation</h6>
                <p>Questionnaires des acquis en amont puis en fin de formation</p>

                <h6>Accessibilité</h6>
                <p>Nos formations sont adaptées au public en situation de handicap</p>

                <h6>Pré requis technique</h6>
                <ul>
                    <li>Navigateur web : Edge, Chrome, Firefox, Safari</li>
                    <li>Système d'exploitation : Mac ou PC, smartphone</li>
                </ul>

                <h4>Programme</h4>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> La compréhension basique du processus de vente</h6></summary>
                <ul>
                    <li>Quels sont les différents types de cycles de vente ?</li>
                    <li>Vente en B2B ou B2C : modalités applicables par le vendeur et dispositions diverses</li>
                    <li>Abordons les spécificités de la vente en B2B</li>
                    <li>La vente en B2C : particularités</li>
                    <li>Qu'est-ce-que la règle des 4 C dans l'univers de la vente ?</li>
                    <li>Abordons le plan de vente et les 7 étapes de la vente incontournables pour atteindre vos objectifs</li>
                    <li>Comment intéresser vos prospects et clients avec un elevatorpitch commercial?</li>
                    <li>Qu'est-ce que l'effet de halo ? Comment l'utiliser dans la démarche commerciale et quels sont ses avantages ?</li>
                    <li>Qu'est-ce que le Customer Centric Selling ?</li>
                    <li>Parlons de l'omnicanalité et de son impact dans l'organisation commerciale</li>
                    <li>Quels sont les motivations et les freins à l'achat des clients ?</li>
                    <li>Adoptez la vente attitude</li>
                    <li>10 conseils pour pratiquer l'écoute active</li>
                    <li>Quelle qualité et quelles conditions pour finaliser une vente ?</li>
                    <li>Et si, dans le business, l'empathie était la principale compétence? A quoi sert-elle ?</li>
                    <li>Quiz module</li>
                </ul></details>
                
                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> La prospection téléphonique</h6></summary>
                <ul>
                    <li>Les 4 étapes indispensables de la prospection client</li>
                    <li>5 règles d'or pour mener sa prospection a la réussite</li>
                    <li>6 étapes clés pour une prospection commerciale digitale efficace</li>
                    <li>Quelles sont les techniques de prospection commerciale efficaces ?</li>
                    <li>La prospection commerciale par recommandation</li>
                    <li>Phase de découverte: oubliez vos a priori !</li>
                    <li>Besoins clients ... Pas d'erreurs d'interprétation</li>
                    <li>L'art du Storytelling ou comment raconter une histoire qui fait vendre !</li>
                    <li>Méthode QQOQCP : sachez utiliser les bonnes questions pour la découverte des besoins de vos clients</li>
                    <li>Comment prospecter et vendre par téléphone?</li>
                    <li>Prospection commerciale : quelles sont les Meilleures pratiques pour conclure une vente avec un prospect difficile ? Comment éveiller l'intérêt qui semble indiffèrent ?</li>
                    <li>Comment réussir un plan de découverte et ainsi améliorer ses ventes?</li>
                    <li>Quiz de module</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Le marketing d'avant vente</h6></summary>
                <ul>
                    <li>Le bouche-à-oreille</li>  
                    <li>Les fondamentaux d'une recommandation réussie</li>  
                    <li>Découvrez nos (13) conseils pour faire de votre salon professionnel une vitrine accueillante</li>  
                    <li>Quelle stratégie marketing pour vendre en B2C : quelles sont les bonnes pratiques du moment ?</li>  
                    <li>Qu'est-ce que l'UX ?</li>  
                    <li>Les différents outils de communication</li>  
                    <li>Comprendre son environnement de vente</li>  
                    <li>Comprendre les différents supports de vente et leurs impacts </li>  
                    <li>Comprendre Google Analytics et les reportings</li>  
                    <li>Comprendre LinkedIn et les reportings</li>  
                    <li>Construire sa E-réputation sur LinkedIn</li>  
                    <li>Traitement des commentaires positifs et négatifs</li>  
                    <li>Comment traiter les données pour améliorer le processus de vente</li>  
                    <li>Répondre aux avis positifs et négatifs de Google</li>  
                    <li>Quiz module</li>               
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les techniques de vente</h6></summary>
                <ul>
                    <li>Comment bien préparer son premier rendez-vous commercial ?</li>
                    <li>Qu'est-ce-que la règle des 4 x 20 pour bien débuter  un entretien commercial ?</li>
                    <li>Quelles sont les techniques de questionnement à maitriser pour mener à bien son entretien de vente ?</li>
                    <li>Comment reformuler ?</li>
                    <li>Qu'est-ce qu'un bon argumentaire commercial ?</li>
                    <li>Comment réussir sa vente en 10 étapes ?</li>
                    <li>Quels outils pour mieux vendre ?</li>
                    <li>3 conseils pour doper vos ventes</li>
                    <li>Quelles sont les meilleures techniques de vente du moment ?</li>
                    <li>Comment mieux vendre ?</li>
                    <li>Conclure une vente : 13 règles d'or conclure une vente</li>
                    <li>Conclure une vente : 10 techniques qui fonctionnent!</li>
                    <li>Techniques de vente en magasin, comment augmenter son chiffre d'affaires ?</li>
                    <li>Quels sont les 8 freins à l'achat les plus couramment rencontrés ?</li>
                    <li>Qu'est-ce qu'un mobile d'achat appelé́ aussi motivation d'achat ? Et comment décrypter les motivations d'achat de vos clients ?</li>
                    <li>Quiz module</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> La prospection téléphonique</h6></summary>
                <ul>
                    <li>Découvrez 7 astuces pour améliorer vos ventes par téléphone</li>
                    <li>Comment booster votre performance en télévente?</li>
                    <li>Comment dépasser vos propres freins et mener votre téléprospection avec succès ?</li>
                    <li>Vendre à un client par téléphone: voici quelques astuces pour améliorer votre technique d'approche</li>
                    <li>Téléprospection: des erreurs à éviter ?  Absolument ! </li>
                    <li>Comment augmenter vos prises de rendez-vous en téléprospection?</li>
                    <li>Script ou guide d'entretien téléphonique: l'indispensable préparation pour une déjouer les barrières lors de vos campagnes de télémarketing Script téléphonique pour vos actions de prospection téléphonique : existe-t-il une méthode parfaite? </li>
                    <li>Nos conseils pour vous aider à réaliser des scripts d'appel efficients pour atteindre vos objectifs</li>
                    <li>Quiz module</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> La négociation</h6></summary>
                <ul>
                    <li>Quelques exemples d'argumentation et de traitement des objections fréquentes</li>
                    <li>Négocier et défendre ses marges</li>
                    <li>Déjouer les pièges des acheteurs dans les négociations difficiles</li>
                    <li>Comment débloquer une vente ?</li>
                    <li>Les 5 erreurs à ne pas commettre en négociation commerciale</li>
                    <li>7 techniques pour réussir ses négociations commerciales</li>
                    <li>Techniques de Closing : remportez vos deals grâce au traitement des objections de vos prospects</li>
                    <li>Comment répondre aux objections commerciales les  plus fréquemment rencontrées  </li>
                    <li>Comment répondre aux objections clients</li>
                    <li>Quiz module</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> L'après-vente et la fidélisation</h6></summary>
                <ul>
                    <li>Le début de la relation client ou comment bien gérer l'après-vente et la fidélisation </li>
                    <li>Comment soigner sa relation client avec un site web ?</li>
                    <li>Comment se différencier en temps de crise ?</li>
                    <li>Quelles sont les meilleures stratégies en matière de fidélisation client ?</li>
                    <li>Comment optimiser la gestion de votre portefeuille clients à l'heure du digital ?</li>
                    <li>La satisfaction client, l'une des meilleures techniques de fidélisation  </li>
                    <li>Les conséquences de l'insatisfaction client</li>
                    <li>Qu'est-ce que la relation client et comment soigner sa mise en application au sein de votre entreprise</li>
                    <li>Comment enrichir une relation client à distance dans une organisation omnicanale?</li>
                    <li>Et si vous écoutiez vos clients mécontents ou insatisfaits? Quelle est l'importance de cette écoute dans la fidélisation?</li>
                    <li>Quiz module</li>
                    <li>Quiz final</li>
                </ul></details>`
            },
            {
                "id": "5e584547-640d-415d-9b98-e194f564d2f5",
                "title": "Management",
                "image": "management_1.png",
                "pdfFile": "/formations/developper_votre_entreprise/management.pdf",
                "body": `<div className='blog-main-content'>
                
                <h4 className='title'>Objectifs pédagogiques:</h4>
                <p>Cette formation Management d'équipe vous permettra d'acquérir, en 26 modules, les bons réflexes et bonnes
                postures. Vous apprendrez les fondamentaux du management : Comment développer la motivation, comment
                déléguer, comment mener des entretiens managériaux ? Vous pourrez aller plusloin sur le sujet grâce aux apports
                sur le management situationnel, l'accompagnement au changement et le positionnement de leader.</p>

                <h4>Temps moyen de formation</h4>
                <p>15 heures</p>

                <h4>Système d'évaluation</h4>
                <p>Questionnaires des acquis en amont puis en fin de formation</p>

                <h4>Accessibilité</h4>
                <p>Nos formations sont adaptées au public en situation de handicap</p>

                <h4>Pré requis technique</h4>
                <ul>
                    <li>Navigateur web : Edge, Chrome, Firefox, Safari</li>
                    <li> Système d'exploitation: Mac ou PC, smartphone</li>
                </ul>

                <h4>Technologie</h4>
                <ul>
                    <li>HTML5</li>
                    <li>Norme SCORM</li>
                </ul>
                
                <hr>
                
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/management_2.png' alt="management photo" />
                <p>Réaliser un Bilan de compétences afin de comprendre et mettre en avant vos atouts.</p>
                </div>
                
                <hr>
                
                <h4>Programme de formation</h4>

                <h5 className='title'>Détail formation : Management d'équipe</h5>
                
                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Manager une mission, une posture</h6></summary>
                <ul>
                    <li>Pourquoi devenir manager ?</li>
                    <li>Quel est le rôle du manager d'équipe ?</li>
                    <li>Quelles sont les devoirs du manager</li
                    <li>Les compétences du manager</li>
                    <li>Quelles sont les qualités et les compétences requises pour devenir manager ?</li>
                    <li>Comment réussir sa prise de fonctions</li>
                    <li>Les erreurs à éviter lors de la prise de fonction</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Apprendre à déléguer</h6></summary>
                <ul>
                    <li>Déléguer : Passer du faire au faire faire</li>
                    <li>Les 6 Étapes de la délégation</li>
                    <li>Prendre des singes ou déléguer</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Quiz - Managerune mission, une posture</h6></summary>
                <ul>
                    <li>Quiz de module</li>
                </ul></details>
                
                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Mener des entretiensavec ses collaborateurs</h6></summary>
                <ul>
                    <li>Les étapes d'un entretien réussi</li>
                    <li>Pratiquer l'écoute active</li>
                    <li>Qu'est-ce qu'un feedback ?</li>
                    <li>Les étapes d'un Feedback réussi</li>
                    <li>Les étapes d'un Feedback réussi</li>
                    <li>Mener l'entretien biannuel professionnel</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Individualiser les stratégies de motivation du manager</h6></summary>
                <ul>
                    <li>Comment individualiser les stratégies de motivation</li>
                    <li>Motiver vos collaborateurspar les signes de reconnaissance</li>
                    <li>Comment favoriser la motivationet les performances des membres de votre équipe ?</li>
                    <li>Stratégie pour remotiver ses collaborateurs</li>
                    <li>Comment créer une allianceavec son chef ?</li>
                    <li>Comment manager un senior</li>
                    <li>Manager plus sénior que soit</li>
                    <li>Manager la génération Y</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Quiz - Mener des entretiens avec ses collaborateurs</h6></summary>
                <ul>
                    <li>Quiz de module</li>
                </ul></details>
                
                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Mener des entretiens avec ses collaborateurs</h6></summary>
                <ul>
                    <li>Qu'est-ce qu'être un Leader : l'ABC du leadership</li>
                    <li>Leadership et influence</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Manager le changement</h6></summary>
                <ul>
                    <li>Mettre en place une culture du changement</li>
                    <li>Un leadership sans peur</li>
                    <li>Agilité organisationnelle</li>
                    <li>Accompagner les transformations</li>
                    <li>Gérer les résistances du changement</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Quiz - Individualiser les stratégies de motivation du manager</h6></summary>
                <ul>
                    <li>Quiz de module</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Le managementsituationnel</h6></summary>
                <ul>
                    <li>Comment maîtriser les 4 styles de management situationnel</li>
                    <li>4 Colors</li>
                    <li>Maîtriser les quatre styles de management</li>
                    <li>Les différentesapproches managériale en fonction des styles</li>
                    <li>Développer des compétencesen partant des besoins</li>
                    <li>Réguler les différents comportements</li>
                    <li>Développement de la performance opérationnelle</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Animer une réunion</h6></summary>
                <ul>
                    <li>Les 3 clés pour animer vos réunions</li>
                    <li>Quelles sont les principalessources de conflit au travail</li>
                    <li>Du désaccord au conflit</li>
                    <li>Quelles sont les coûts et les risques de conflits</li>
                    <li>Le conflit peut-il être une opportunité ?</li>
                    <li>La mécaniqueinterne du conflit</li>
                    <li>Les 5 étapes du conflits</li>
                    <li>Les facteurs d’inflation du conflit</li>
                    <li>Les 3 cerveaux, le stress et le conflit</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Quiz - Le management situationnel</h6></summary>
                <ul>
                    <li>Quiz de module</li>
                </ul></details>


                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Développer une intelligence relationnelle</h6></summary>
                <ul>
                    <li>Intro</li>
                    <li>Établir un rapport d'égal à égal pour vous affirmer avec tact/li>

                    <li>Cultiver un regard positif</li>
                    <li>S'affirmer sainement, adopter une attitude constructive</li>
                    <li>Commentse contrôler et gérer un conflitde manière constructive</li>
                    <li>Pratiquer l'écoute active</li>
                    <li>Poser des limites et dire non</li>
                    <li>Les états du moi, parent, adulte, enfant</li>
                    <li>Sortir des jeux relationnels</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Résoudre un conflit ouvert</h6></summary>
                <ul>
                    <li>Intro</li>
                    <li>Anticiper et désamorcerles conflitslatents</li>
                    <li>Les préalablesavant de gérer le conflit</li>
                    <li>Gestion des conflitsinternes- Comments'y prendre</li>
                    <li>Commentse préparer à aborder un sujet conflictuel</li>
                    <li>Organiserla rencontre pour dénouerle conflit</li>
                    <li>Désamorcerl'escaladed'un conflit</li>
                    <li>Un conflitbien résolu, qu'est-ce que c'est ?</li>
                    <li>Résoudreun conflitavec un client en 5 étapes</li>
                    <li>La réunion de régulation,régler les problèmes</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Adopter une attitude constructive dans le conflit</h6></summary>
                <ul>
                    <li>Intro</li>
                    <li>Les attitudes face aux conflits selon Thomas Kilmann</li>
                    <li>Gérer nos conflits intérieurs</li>
                    <li>Maîtriser l'art du contournement</li>
                    <li>Répondre aux objectionsdu client ou du collaborateur</li>
                    <li>La méthode DESC - communication non violente</li>
                    <li>Questionner et changer ses perceptions</li>
                    <li>Oser le lâcher prise</li>
                    <li>Gérer l'après conflit</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Arbitre au médiateur- Gérer un conflit en tant que tiers</h6></summary>
                <ul>
                    <li>Les approches de la gestion de conflits</li>
                    <li>Les avantages et rôles du médiateur</li>
                    <li>Être manager et médiateur</li>
                    <li>Les qualités du médiateur</li>
                    <li>Une médiation réussie en 8 étapes</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Créer un environnement de travail plus collaboratif</h6></summary>
                <ul>
                    <li>Intro</li>
                    <li>Des valeurs pour faciliterla collaboration</li>
                    <li>La charte relationnelle/ Le code de bonne conduite</li>
                    <li>Les chartes de l'engagementmutuel</li>
                    <li>Clarifier rôles et responsabilités pour éviter le conflit</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Ouvrir des paradigmes au-delà du conflit</h6></summary>
                <ul>
                    <li>La gestion de conflits dans l'entreprise libérée</li>
                    <li>Les jeux de domination, pourquoi nous cherchons le pouvoir</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communiquer dans le désaccord</h6></summary>
                <ul>
                    <li>Qu'est-ce qu'un désaccord</li>
                    <li>Ce qu'il faut retenir avant de formuler un désaccord</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Améliorer ses talents relationnels</h6></summary>
                <ul>
                    <li>Améliorer ses talents relationnels - Partie 1</li>
                    <li>Améliorer ses talents relationnels - Partie 2</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Management à distance</h6></summary>
                <ul>
                    <li>L'histoire du managementà distance</li>
                    <li>Genèse : Du manager au E-manager</li>
                    <li>Les spécificités du management à distance</li>
                    <li>Quand la distance ne remet pas en question les pratiques habituelles du management</li>
                    <li>Une pratique managériale évolutive pour prendre acte de la distance</li>
                    <li>Le concept de distance - partie 1</li>
                    <li>Le concept de distance - partie 2</li>
                    <li>La notion d'équipe à distance</li>
                    <li>Les risques associés aux équipes dispersées</li>
                    <li>Les déterminants de la performance des équipes distantes</li>
                    <li>Les outils pour gérerla distance - Partie 1</li>
                    <li>Les outils pour gérerla distance - Partie 2</li>
                    <li>Les outils pour gérerla distance - Partie 3</li>
                    <li>Les bonnes pratiques du management à distance</li>
                    <li>Le rôle du manager- Partie 1</li>
                    <li>Le rôle du manager- Partie 2</li>
                    <li>Le rôle du manager- Partie 3</li>
                    <li>L'accompagnement RH pour gérer la distance - Partie 1</li>
                    <li>L'accompagnement RH pour gérer la distance - Partie 2</li>
                    <li>Les outils</li>
                    <li>Conclusion</li>
                </ul></details>
            </div>`
            },
            {
                "id": "de98cb1e-c55f-4ec0-85fd-56f396f10124",
                "title": "Réseaux sociaux",
                "image": "reseauxsociaux_1.png",
                "pdfFile": "/formations/developper_votre_entreprise/reseaux_sociaux.pdf",
                "body": `<div className='blog-main-content'>
                <p>Grâce à cette formation vous pourrez optimiser la visibilité de votre entreprise sur les réseaux sociaux</p>
                
                <h4 className='title'>Vous pourrez par la suite :</h4>
                <ul>
                    <li>Définir les tactiques opérationnelles sur les médias sociaux</li> 
                    <li>Définir un plan de contenus sur les médias sociaux</li> 
                    <li>Maîtriser les astuces de publication</li>
                </ul>
                
                <h4 className='title'>Objectifs :</h4>
                <ul>
                    <li>Apprendre à publier sur les réseaux sociaux</li> 
                    <li>Différencier les différentes techniques de marketing web</li> 
                </ul>
                
                <h4 className='title'>Certification :</h4>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I-formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre 2018, Décret n°2019-565 du 6 juin 2019).</p>
                
                <h4 className='title'>Financement :</h4>
                <p> Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>
                
                <div className="text-center rounded" className="thumbnail block-alignwide">
                    <p>Effectuer sa publicité afin d'agrandir son portefeuille client</p>
                    <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/reseauxsociaux_2.png' alt="réseaux sociaux" />
                </div>

                <hr>

                <h4>Programme</h4>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Objectifs pédagogiques</h6></summary>
                <p>Cette formation Réseaux sociaux vous permettra, au travers de 14 modules et quiz, de :</p>
                <ul>
                    <li>définir les tactiques opérationnelles sur les médias sociaux</li>
                    <li>définir un plan de contenus sur les médias sociaux</li>
                    <li>maîtriser les astuces de publication</li>
                    <li>connaître les outils indispensables pour une campagne développer sa culture digitale</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Système d'évaluation</h6></summary>
                <p>Questionnaires des acquis en amont puis en fin de formation</p>
                </details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Pré requis technique</h6></summary>
                <ul>
                    <li>Navigateur web : Edge, Chrome, Firefox, Safari</li>
                    <li>Système d'exploitation : Mac ou PC, smartphone</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Les fondamentaux du métier de Community Manager</h6></summary>
                <ul>
                    <li>L'évolution des réseaux sociaux</li>
                    <li>Le Community Manager</li>
                    <li>La communauté du Community manager</li>
                </ul></details>
                
                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Quiz - Les fondamentaux du métier de CM</h6></summary>
                <ul>
                    <li>Quiz de module</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> La base des réseaux sociaux</h6></summary>
                <ul>
                    <li>Facebook</li>
                    <li>Instagram</li>
                    <li>LinkedIn</li>
                    <li>YouTube</li>
                    <li>Twitter</li>
                    <li>TikTok</li>
                    <li>Pinterest</li>   
                    <li>Snapchat</li>               
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Quiz - La base des réseaux sociaux</h6></summary>
                <ul>
                    <li>Quiz de module</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Cerner les enjeux comportementaux des internautes pour construire votre projet social media</h6></summary>
                <ul>
                    <li>Les indicateurs clés de performance à analyser</li>
                    <li>La e-réputation</li>
                    <li>La veille concurrentielle et la veille sociale médias</li>
                    <li>Le projet social media</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Quiz - Cerner les enjeux comportementauxdes internautespour construire votre projet social media</h6></summary>
                <ul>
                    <li>Quiz de module</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Mettre en place une stratégie Social Média</h6></summary>
                <ul>
                    <li>Les acteurs de la stratégie digitale et opérationnelle</li>
                    <li>Faire des médias sociaux un outil d'acquisition client pour l'entreprise </li>
                    <li>Le Brand content et le modèle POEM - Partie 1</li>
                    <li>Le Brand content et le modèle POEM - Partie 1</li>
                    <li>Les outils d'organisation</li>
                    <li>Les outils de veille - partie 1</li>
                    <li>Les outils de veille - partie 2</li>
                    <li>Les outils de création - partie 1</li>
                    <li>Les outils de création - partie 2</li>
                    <li>Les outils de planification</li>
                </ul></details>
                
                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Quiz - Mettre en place une stratégie Social Média</h6></summary>
                <ul>
                    <li>Quiz de module</li>
                </ul></details>
                
                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Pinterest Ads</h6></summary>
                <ul>
                    <li>Introduction</li>
                    <li>Premierspas sur Pinterest</li>
                    <li>Le moteur de recherche Pinterest</li>
                    <li>Pinterest, pour quel type d'entreprise ?</li>
                    <li>La création de contenu sur Pinterest</li>
                    <li>La sponsorisation sur Pinterest</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Quiz - Pinterest Ads</h6></summary>
                <ul>
                    <li>Quiz de module</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> TikTok Ads</h6></summary>
                <ul>
                    <li>Origine De l'application TikTok</li>
                    <li>TikTok Business Manager</li>
                    <li>Comment créer un TikTok Ads</li>
                    <li>Quels contenus créer sur TikTok Ads ?</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Quiz - TikTok Ads</h6></summary>
                <ul>
                    <li>Quiz de module</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Snapchat Ads</h6></summary>
                <ul>
                    <li>Snap, comment ça fonctionne ?</li>
                    <li>SnapChat Business Manager</li>
                    <li>Comment créer une annonce sur SnapChat</li>
                    <li>Comment Élargir son influence sur SnapChat</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Quiz - Snapchat Ads</h6></summary>
                <ul>
                    <li>Quiz de module</li>
                </ul></details>
                `
            }
        ]
    },
    {
        "title": "Langues",
        "slogan": "Franchissez la barrière de la langue",
        "items": [
            {
                "id": "54fcf605-071f-41a6-86cb-c75bb8c9461f",
                "title": "Allemand",
                "image": "allemand_1.png",
                "pdfFile": "/formations/langues/allemand.pdf",
                "body": `<div className='blog-main-content'>
                <p>Grâce à l'Allemand vous pourrez communiquer avec vos collaborateurs étrangers</p>

                <h4 className='title'>Vous pourrez par la suite :</h4>
                <ul><li>Être plus à l'aise dans le milieu professionnel</li>
                <li>Se perfectionner dans la vie quotidienne en Allemand</li></ul>

                <h4 className='title'>Objectifs :</h4>
                <p>Renforcer les bases grammaticales et lexicales en allemand. Acquérir les réflexes langagiers nécessaires à une communication fluide et précise en français professionnel et/ou du quotidien. Savoir rédiger un mail. Gérer un appel téléphonique.</p>

                <h4 className='title'>Certification :</h4>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I- formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre2018, Décret n° 2019-565 du 6 juin 2019).</p>

                <h4 className='title'>Financement :</h4>
                <p>Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>

                <hr>

                <p>Votre progression, compréhension orale et écrite en italien évoluera à votre rythme</p>
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/allemand_2.png' alt="Allemand picture" />
                </div>

                <hr>
                
                <p>L'étude de l'italien en visio avec nous est facile, nous respectons votre allure d'apprentissage.</p>
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/allemand_3.png' alt="Allemand picture" />
                </div>

                <hr>

                <h4>Programme</h4>
                                
                <h6>Objectifs pédagogiques</h6>
                <p>Renforcer les bases grammaticales et lexicales en allemand</p>
                <p>Acquérir les réflexes langagiers nécessaires à une communication fluide et précise en allemand professionnel et/ou du quotidien. Savoir rédiger un mail. Gérer un appel téléphonique</p>

                <h6>Système d'évaluation</h6>
                <p>Questionnaires des acquis en amont puis en fin de la formation.</p>

                <h6>Accessibilité</h6>
                <p>Nos formations sont adaptées au public en situation de handicap</p>

                <h6>Pré requis technique</h6>
                <p>Aucun, le programme est conçu en fonction du test de positionnement</p>

                <hr>

                <h4>Détail formation : Allemand</h4>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication sur la vie quotidienne</h6></summary>
                <ul>
                <li>Apprendre à se présenter</li>
                <li>Apprendre à se saluer</li>
                <li>Apprendre à se situer dans le temps</li>
                <li>Exprimer un souhait, une envie</li>
                <li>Comprendre et utiliser les chiffres</li>
                <li>Exprimer la négation</li>
                <li>Décrire des événements passés</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication sur la vie quotidienne</h6></summary>
                <ul>
                <li>Passer un appel téléphonique</li>
                <li>Décrire une situation</li>
                <li>Vérifier clarifier une situation au téléphone</li>
                <li>Émettre des suggestions</li>
                <li>Connaître les différentes salutations</li>
                <li>Demander confirmation des dates et numéros de téléphone</li>
                <li>Commande de marchandises</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Rédiger un mail</h6></summary>
                <ul>
                <li>Connaître les formules de politesse</li>
                <li>Apprendre à résumer une situation ou problématique</li>
                <li>Rédiger de façon formelle et informelle</li>
                <li>Comprendre les e-mails abrégés</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication dans la vie professionnelle en face à face</h6></summary>
                <ul>
                <li>Informer votre supérieur d'une situation au bureau</li>
                <li>Planifier une conférence d'affaires</li>
                <li>Parler de l'activité de votre entreprise</li>
                <li>Discuter de la technologie</li>
                <li>Donner des conseils aux collègues</li>
                <li>Discuter de la façon d'aborder un problème</li>
                <li>Décrire des objets : taille. Poids. Dimension</li>
                <li>Apprendre à mener un entretien d'embauche</li>
                <li>Apprendre à mener une réunion en décrivant des graphiques</li>
                <li>Apprendre à exprimer des doutes face a un projet</li>
                <li>Expliquer des procédures</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication sociale dans la vie professionnelle</h6></summary>
                <ul>
                <li>Améliorer ses connaissances culturelles</li>
                <li>Parler de ses loisirs</li>
                <li>Parler de son trajet pour arriver au travail</li>
                <li>Réserver un hôtel pour les déplacements professionnels</li>
                <li>Préparer la certification</li>
                <li>Comprendre le fonctionnement du test</li>
                <li>Etablir une stratégie pour accroître sa performance au test</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Certification à l'issue de la formation</h6></summary>
                <p>Certification CLOE</p>
</div>`
            },
            {
                "id": "928696ba-31f5-46a4-861c-31e0b1a04746",
                "title": "Anglais",
                "image": "anglais_1.png",
                "pdfFile": "/formations/langues/anglais.pdf",
                "body": `<div className='blog-main-content'>
                <p>Grâce à l'anglais vous pourrez communiquer avec vos collaborateurs étrangers</p>

                <h4 className='title'>Vous pourrez par la suite :</h4>
                <ul><li>Être plus à l'aise dans le milieu professionnel</li>
                <li>Se perfectionner dans la vie quotidienne en Anglais</li></ul>

                <h4 className='title'>Objectifs :</h4>
                <p>Renforcer les bases grammaticales et lexicales en anglais. Acquérir les réflexes langagiers nécessaires à une communication fluide et précise en français professionnel et/ou du quotidien. Savoir rédiger un mail. Gérer un appel téléphonique.</p>

                <h4 className='title'>Certification :</h4>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I- formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre2018, Décret n° 2019-565 du 6 juin 2019).</p>
                <h4 className='title'>Certification à l'issue de la formation</h4>
                <p>Certification LINGUASKILL</p>
                
                <h4 className='title'>Financement :</h4>
                <p>Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>

                <hr>

                <p>Votre progression, compréhension orale et écrite en anglais évoluera à votre rythme</p>
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/anglais_2.png' alt="anglais picture" />
                </div>

                <hr>
                
                <p>L'étude de l'anglais en visio avec nous est facile, nous respectons votre allure d'apprentissage.</p>
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/anglais_3.png' alt="anglais picture" />
                </div>

                <hr>

                <h4>Programme</h4>
                                
                <h6>Objectifs pédagogiques</h6>
                <p>Renforcer les bases grammaticales et lexicales en anglais</p>
                <p>Acquérir les réflexes langagiers nécessaires à une communication fluide et précise en anglais professionnel et/ou du quotidien. Savoir rédiger un mail. Gérer un appel téléphonique</p>

                <h6>Système d'évaluation</h6>
                <p>Questionnaires des acquis en amont puis en fin de la formation.</p>

                <h6>Accessibilité</h6>
                <p>Nos formations sont adaptées au public en situation de handicap</p>

                <h6>Pré requis</h6>
                <p>Aucun, le programme est conçu en fonction du test de positionnement</p>

                <hr>

                <h4>Détail formation : Anglais</h4>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication sur la vie quotidienne</h6></summary>
                <ul>
                <li>Apprendre à se présenter</li>
                <li>Apprendre à se saluer</li>
                <li>Apprendre à se situer dans le temps</li>
                <li>Exprimer un souhait, une envie</li>
                <li>Comprendre et utiliser les chiffres</li>
                <li>Exprimer la négation</li>
                <li>Décrire des événements passés</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication sur la vie quotidienne</h6></summary>
                <ul>
                <li>Passer un appel téléphonique</li>
                <li>Décrire une situation</li>
                <li>Vérifier clarifier une situation au téléphone</li>
                <li>Émettre des suggestions</li>
                <li>Connaître les différentes salutations</li>
                <li>Demander confirmation des dates et numéros de téléphone</li>
                <li>Commande de marchandises</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Rédiger un mail</h6></summary>
                <ul>
                <li>Connaître les formules de politesse</li>
                <li>Apprendre à résumer une situation ou problématique</li>
                <li>Rédiger de façon formelle et informelle</li>
                <li>Comprendre les e-mails abrégés</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication dans la vie professionnelle en face à face</h6></summary>
                <ul>
                <li>Informer votre supérieur d'une situation au bureau</li>
                <li>Planifier une conférence d'affaires</li>
                <li>Parler de l'activité de votre entreprise</li>
                <li>Discuter de la technologie</li>
                <li>Donner des conseils aux collègues</li>
                <li>Discuter de la façon d'aborder un problème</li>
                <li>Décrire des objets : taille. Poids. Dimension</li>
                <li>Apprendre à mener un entretien d'embauche</li>
                <li>Apprendre à mener une réunion en décrivant des graphiques</li>
                <li>Apprendre à exprimer des doutes face a un projet</li>
                <li>Expliquer des procédures</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication sociale dans la vie professionnelle</h6></summary>
                <ul>
                <li>Améliorer ses connaissances culturelles</li>
                <li>Parler de ses loisirs</li>
                <li>Parler de son trajet pour arriver au travail</li>
                <li>Réserver un hôtel pour les déplacements professionnels</li>
                <li>Préparer la certification</li>
                <li>Comprendre le fonctionnement du test</li>
                <li>Etablir une stratégie pour accroître sa performance au test</li>
                </ul></details>
</div>`
            },
            {
                "id": "943168c4-8c97-4355-828e-c81420ad7710",
                "title": "Espagnol",
                "image": "espagnol_1.png",
                "pdfFile": "/formations/langues/espagnol.pdf",
                "body": `<div className='blog-main-content'>
                <p>Grâce à l'Espagnol vous pourrez communiquer avec vos collaborateurs étrangers</p>

                <h4 className='title'>Vous pourrez par la suite :</h4>
                <ul><li>Être plus à l'aise dans le milieu professionnel</li>
                <li>Se perfectionner dans la vie quotidienne en l'espagnol</li></ul>

                <h4 className='title'>Objectifs :</h4>
                <p>Renforcer les bases grammaticales et lexicales en espagnol. Acquérir les réflexes langagiers nécessaires à une communication fluide et précise en français professionnel et/ou du quotidien. Savoir rédiger un mail. Gérer un appel téléphonique.</p>

                <h4 className='title'>Certification :</h4>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I- formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre2018, Décret n° 2019-565 du 6 juin 2019).</p>
                <h4 className='title'>Certification à l'issue de la formation</h4>
                <p>Certification CLOE</p>
                
                <h4 className='title'>Financement :</h4>
                <p>Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>

                <hr>

                <p>Votre progression, compréhension orale et écrite en espagnol évoluera à votre rythme</p>
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/espagnol_2.png' alt="espagnol picture" />
                </div>

                <hr>
                
                <p>L'étude de l'espagnol en visio avec nous est facile, nous respectons votre allure d'apprentissage.</p>
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/espagnol_3.png' alt="espagnol picture" />
                </div>

                <hr>

                <h4>Programme</h4>
                                
                <h6>Objectifs pédagogiques</h6>
                <p>Renforcer les bases grammaticales et lexicales en Espanol</p>
                <p>Acquérir les réflexes langagiers nécessaires à une communication fluide et précise en espagnol professionnel et/ou du quotidien. Savoir rédiger un mail. Gérer un appel téléphonique</p>

                <h6>Système d'évaluation</h6>
                <p>Questionnaires des acquis en amont puis en fin de la formation.</p>

                <h6>Accessibilité</h6>
                <p>Nos formations sont adaptées au public en situation de handicap</p>

                <h6>Pré requis</h6>
                <p>Aucun, le programme est conçu en fonction du test de positionnement</p>

                <hr>

                <h4>Détail formation : Espagnol</h4>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication sur la vie quotidienne</h6></summary>
                <ul>
                <li>Apprendre à se présenter</li>
                <li>Apprendre à se saluer</li>
                <li>Apprendre à se situer dans le temps</li>
                <li>Exprimer un souhait, une envie</li>
                <li>Comprendre et utiliser les chiffres</li>
                <li>Exprimer la négation</li>
                <li>Décrire des événements passés</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication sur la vie quotidienne</h6></summary>
                <ul>
                <li>Passer un appel téléphonique</li>
                <li>Décrire une situation</li>
                <li>Vérifier clarifier une situation au téléphone</li>
                <li>Émettre des suggestions</li>
                <li>Connaître les différentes salutations</li>
                <li>Demander confirmation des dates et numéros de téléphone</li>
                <li>Commande de marchandises</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Rédiger un mail</h6></summary>
                <ul>
                <li>Connaître les formules de politesse</li>
                <li>Apprendre à résumer une situation ou problématique</li>
                <li>Rédiger de façon formelle et informelle</li>
                <li>Comprendre les e-mails abrégés</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication dans la vie professionnelle en face à face</h6></summary>
                <ul>
                <li>Informer votre supérieur d'une situation au bureau</li>
                <li>Planifier une conférence d'affaires</li>
                <li>Parler de l'activité de votre entreprise</li>
                <li>Discuter de la technologie</li>
                <li>Donner des conseils aux collègues</li>
                <li>Discuter de la façon d'aborder un problème</li>
                <li>Décrire des objets : taille. Poids. Dimension</li>
                <li>Apprendre à mener un entretien d'embauche</li>
                <li>Apprendre à mener une réunion en décrivant des graphiques</li>
                <li>Apprendre à exprimer des doutes face a un projet</li>
                <li>Expliquer des procédures</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication sociale dans la vie professionnelle</h6></summary>
                <ul>
                <li>Améliorer ses connaissances culturelles</li>
                <li>Parler de ses loisirs</li>
                <li>Parler de son trajet pour arriver au travail</li>
                <li>Réserver un hôtel pour les déplacements professionnels</li>
                <li>Préparer la certification</li>
                <li>Comprendre le fonctionnement du test</li>
                <li>Etablir une stratégie pour accroître sa performance au test</li>
                </ul></details>
</div>`
            },
            {
                "id": "9dfc0752-7dd6-4949-a7df-8a158de9e75a",
                "title": "Français",
                "image": "francais_1.png",
                "pdfFile": "/formations/langues/francais.pdf",
                "body": `<div className='blog-main-content'>
                <p>Améliorer votre niveau en Français afin d'être à l'aise dans toute les situations de la vie professionnel et personnel</p>

                <h4 className='title'>Vous pourrez par la suite :</h4>
                <ul><li>Être plus à l'aise dans le milieu professionnel</li>
                <li>Se perfectionner dans la vie quotidienne en Français</li></ul>

                <h4 className='title'>Objectifs :</h4>
                <p>Renforcer les bases grammaticales et lexicales en français. Acquérir les réflexes langagiers nécessaires à une communication fluide et précise en français professionnel et/ou du quotidien. Savoir rédiger un mail. Gérer un appel téléphonique.</p>

                <h4 className='title'>Certification :</h4>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I- formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre2018, Décret n° 2019-565 du 6 juin 2019).</p>
                <h4 className='title'>Certification à l'issue de la formation</h4>
                <p>Certification CLOE</p>
                
                <h4 className='title'>Financement :</h4>
                <p>Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>

                <hr>

                <p>Votre progression, compréhension orale et écrite en Français évoluera à votre rythme</p>
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/francais_2.png' alt="francais picture" />
                </div>

                <hr>
                
                <p>L'étude du français en visio avec nous est facile, nous respectons votre allure d'apprentissage.</p>
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/francais_3.png' alt="francais picture" />
                </div>

                <hr>

                <h4>Programme</h4>
                                
                <h6>Objectifs pédagogiques</h6>
                <p>Renforcer les bases grammaticales et lexicales en français</p>
                <p>Acquérir les réflexes langagiers nécessaires à une communication fluide et précise en français professionnel et/ou du quotidien. Savoir rédiger un mail. Gérer un appel téléphonique</p>

                <h6>Système d'évaluation</h6>
                <p>Questionnaires des acquis en amont puis en fin de la formation.</p>

                <h6>Accessibilité</h6>
                <p>Nos formations sont adaptées au public en situation de handicap</p>

                <h6>Pré requis</h6>
                <p>Aucun, le programme est conçu en fonction du test de positionnement</p>

                <hr>

                <h4>Détail formation : Français</h4>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication sur la vie quotidienne</h6></summary>
                <ul>
                <li>Apprendre à se présenter</li>
                <li>Apprendre à se saluer</li>
                <li>Apprendre à se situer dans le temps</li>
                <li>Exprimer un souhait, une envie</li>
                <li>Comprendre et utiliser les chiffres</li>
                <li>Exprimer la négation</li>
                <li>Décrire des événements passés</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication sur la vie quotidienne</h6></summary>
                <ul>
                <li>Passer un appel téléphonique</li>
                <li>Décrire une situation</li>
                <li>Vérifier clarifier une situation au téléphone</li>
                <li>Émettre des suggestions</li>
                <li>Connaître les différentes salutations</li>
                <li>Demander confirmation des dates et numéros de téléphone</li>
                <li>Commande de marchandises</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Rédiger un mail</h6></summary>
                <ul>
                <li>Connaître les formules de politesse</li>
                <li>Apprendre à résumer une situation ou problématique</li>
                <li>Rédiger de façon formelle et informelle</li>
                <li>Comprendre les e-mails abrégés</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication dans la vie professionnelle en face à face</h6></summary>
                <ul>
                <li>Informer votre supérieur d'une situation au bureau</li>
                <li>Planifier une conférence d'affaires</li>
                <li>Parler de l'activité de votre entreprise</li>
                <li>Discuter de la technologie</li>
                <li>Donner des conseils aux collègues</li>
                <li>Discuter de la façon d'aborder un problème</li>
                <li>Décrire des objets : taille. Poids. Dimension</li>
                <li>Apprendre à mener un entretien d'embauche</li>
                <li>Apprendre à mener une réunion en décrivant des graphiques</li>
                <li>Apprendre à exprimer des doutes face a un projet</li>
                <li>Expliquer des procédures</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication sociale dans la vie professionnelle</h6></summary>
                <ul>
                <li>Améliorer ses connaissances culturelles</li>
                <li>Parler de ses loisirs</li>
                <li>Parler de son trajet pour arriver au travail</li>
                <li>Réserver un hôtel pour les déplacements professionnels</li>
                <li>Préparer la certification</li>
                <li>Comprendre le fonctionnement du test</li>
                <li>Etablir une stratégie pour accroître sa performance au test</li>
                </ul></details>
</div>`
            },
            {
                "id": "1e2af30d-da6d-41c8-8117-e4a194714b21",
                "title": "Italien",
                "image": "italien_1.png",
                "pdfFile": "/formations/langues/italien.pdf",
                "body": `<div className='blog-main-content'>
                <p>Grâce à l'Italien vous pourrez communiquer avec vos collaborateurs étrangers</p>

                <h4 className='title'>Vous pourrez par la suite :</h4>
                <ul><li>Être plus à l'aise dans le milieu professionnel</li>
                <li>Se perfectionner dans la vie quotidienne en Italien</li></ul>

                <h4 className='title'>Objectifs :</h4>
                <p>Renforcer les bases grammaticales et lexicales en italien. Acquérir les réflexes langagiers nécessaires à une communication fluide et précise en français professionnel et/ou du quotidien. Savoir rédiger un mail. Gérer un appel téléphonique.</p>

                <h4 className='title'>Certification :</h4>
                <p>La formation est sanctionnée par une certification enregistrée dans les répertoires de la certification professionnelle, elle est reconnue par l'État. I- formation.com est un organisme certifié qualité par le Référentiel National Qualité Qualiopi (loi du 5 septembre2018, Décret n° 2019-565 du 6 juin 2019).</p>
                <h4 className='title'>Certification à l'issue de la formation</h4>
                <p>Certification CLOE</p>
                
                <h4 className='title'>Financement :</h4>
                <p>Toutes nos formations ont une prise en charge des frais pédagogiques par les fonds mutualisés de la formation professionnelle. L'inscription se déroule entièrement en ligne en moins d'une minute. Il n'y a aucune formalité compliquée à effectuer pour vous inscrire avec prise en charge, et vous pouvez commencer tout de suite.</p>

                <hr>

                <p>Votre progression, compréhension orale et écrite en italien évoluera à votre rythme</p>
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/italien_2.png' alt="italien picture" />
                </div>

                <hr>
                
                <p>L'étude de l'italien en visio avec nous est facile, nous respectons votre allure d'apprentissage.</p>
                <div className="text-center rounded" className="thumbnail block-alignwide">
                <img className="text-center rounded" className="radius-small w-100 mb--30" src='/images/formations/italien_3.png' alt="italien picture" />
                </div>

                <hr>

                <h4>Programme</h4>
                                
                <h6>Objectifs pédagogiques</h6>
                <p>Renforcer les bases grammaticales et lexicales en italien</p>
                <p>Acquérir les réflexes langagiers nécessaires à une communication fluide et précise en italien professionnel et/ou du quotidien. Savoir rédiger un mail. Gérer un appel téléphonique</p>

                <h6>Système d'évaluation</h6>
                <p>Questionnaires des acquis en amont puis en fin de la formation.</p>

                <h6>Accessibilité</h6>
                <p>Nos formations sont adaptées au public en situation de handicap</p>

                <h6>Pré requis</h6>
                <p>Aucun, le programme est conçu en fonction du test de positionnement</p>

                <hr>

                <h4>Détail formation : Allemand</h4>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication sur la vie quotidienne</h6></summary>
                <ul>
                <li>Apprendre à se présenter</li>
                <li>Apprendre à se saluer</li>
                <li>Apprendre à se situer dans le temps</li>
                <li>Exprimer un souhait, une envie</li>
                <li>Comprendre et utiliser les chiffres</li>
                <li>Exprimer la négation</li>
                <li>Décrire des événements passés</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication sur la vie quotidienne</h6></summary>
                <ul>
                <li>Passer un appel téléphonique</li>
                <li>Décrire une situation</li>
                <li>Vérifier clarifier une situation au téléphone</li>
                <li>Émettre des suggestions</li>
                <li>Connaître les différentes salutations</li>
                <li>Demander confirmation des dates et numéros de téléphone</li>
                <li>Commande de marchandises</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Rédiger un mail</h6></summary>
                <ul>
                <li>Connaître les formules de politesse</li>
                <li>Apprendre à résumer une situation ou problématique</li>
                <li>Rédiger de façon formelle et informelle</li>
                <li>Comprendre les e-mails abrégés</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication dans la vie professionnelle en face à face</h6></summary>
                <ul>
                <li>Informer votre supérieur d'une situation au bureau</li>
                <li>Planifier une conférence d'affaires</li>
                <li>Parler de l'activité de votre entreprise</li>
                <li>Discuter de la technologie</li>
                <li>Donner des conseils aux collègues</li>
                <li>Discuter de la façon d'aborder un problème</li>
                <li>Décrire des objets : taille. Poids. Dimension</li>
                <li>Apprendre à mener un entretien d'embauche</li>
                <li>Apprendre à mener une réunion en décrivant des graphiques</li>
                <li>Apprendre à exprimer des doutes face a un projet</li>
                <li>Expliquer des procédures</li>
                </ul></details>

                <details className="bg-gray-300 open:bg-amber-200 duration-300"><summary className="bg-inherit px-5 py-3 text-lg cursor-pointer"><h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> </svg> Communication sociale dans la vie professionnelle</h6></summary>
                <ul>
                <li>Améliorer ses connaissances culturelles</li>
                <li>Parler de ses loisirs</li>
                <li>Parler de son trajet pour arriver au travail</li>
                <li>Réserver un hôtel pour les déplacements professionnels</li>
                <li>Préparer la certification</li>
                <li>Comprendre le fonctionnement du test</li>
                <li>Etablir une stratégie pour accroître sa performance au test</li>
                </ul></details>
</div>`
            }
        ]
    }
]