import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram } from "react-icons/fa"

const Footer = () => {
    return (
        <>
            <footer className="eduvibe-footer-one edu-footer footer-style-default">
                <div className="footer-top">
                    <div className="container eduvibe-animated-shape">
                        <div className="row g-5">
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="edu-footer-widget">
                                    <div className="logo">
                                        <Link to={process.env.PUBLIC_URL + "/"}>
                                            <img className="logo-light" src="/images/logo/logo-white.png" alt="Footer Logo" />
                                        </Link>
                                    </div>
                                    <ul className="social-share content-center">
                                        <li><a href="mailto:info@visio-form.fr"><i className="icon-mail-line-2"></i></a></li>
                                        {/* <li><a href="/"><i><FaTiktok /></i></a></li> */}
                                        <li><a href="https://www.facebook.com/VisioForm-101146882568197/"><i className="icon-Fb"></i></a></li>
                                        <li><a href="https://instagram.com/visio_form?igshid=YmMyMTA2M2Y="><i><FaInstagram /></i></a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-6 col-sm-6 col-12"></div>

                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="">
                                    <div className="">
                                    <a href="/charte-deontologie-entreprise-formation-cpf.pdf"><img height="250" width="250" src="/images/chartedeontologieCPF-1.png" alt="Logos Certif" /></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-6 col-sm-6 col-12"></div>

                            {/* <div className="col-lg-3 col-md-6 col-sm-6 col-12"> */}
                                {/* <div className="edu-footer-widget quick-link-widget"> */}
                                    {/* <img className="p-3" height="250" width="250" src="/images/toeic.png" alt="Logos Certif" /> */}
                                    {/* <img className="p-3" height="250" width="250" src="/images/chloe.png" alt="Logos Certif" /> */}
                                {/* </div> */}
                            {/* </div> */}

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget">
                                    <h5 className="widget-title">Contactez-nous</h5>
                                    <div className="inner">
                                        <div className="widget-information">
                                            <ul className="information-list">
                                                <li><i className="icon-map-pin-line"></i>111 Avenue Victor Hugo 75116 Paris</li>
                                                {/* <li><i className="icon-phone-fill"></i><a href="tel: + 1 (237) 382-2839">+ 1 (237) 382-2839</a></li> */}
                                                <li><i className="icon-mail-line-2"></i><a target="_blank" rel="noreferrer" href="mailto:info@visio-form.fr">info@visio-form.fr</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-21-01.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-35.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area copyright-default">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <p>Copyright 2022 - Visioform</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;