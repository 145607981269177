import React from 'react';
import { useParams } from 'react-router-dom';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import FormationsData from '../../data/formations/FormationsData';

const CourseDetails = () => {
    const { id } = useParams();
    let data;

    for(let category of FormationsData) {
        const item = category.items.find(item => item.id === id) 
        
        if(item !== undefined) {
            data = item
        }
    }

    return (
        <>
            <SEO title={ data.title } />

            <Layout isCourse="true">
                <BreadcrumbOne
                    title={ data.title }
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Formation"
                    pdfFile={ data.pdfFile }
                />
                <div className="edu-blog-details-area edu-section-gap">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="blog-details-1 style-variation3">

                                    <div className="content-blog-top">
                                        <div className="thumbnail block-alignwide">
                                            <img className="radius-small w-100 mb--30" src={`/images/formations/${data.image}`} alt="Blog Thumb" />
                                        </div>

                                        {/* <div class="mx-auto my-20 w-[600px] drop-shadow rounded-md"> */}
        {/* <h1 class="text-2xl font-italic font-light text-center text-white mb-10">KindaCode.com - FAQ</h1> */}

        {/* <!-- The First FAQ --> */}
        {/* <details class="bg-gray-300 open:bg-amber-200 duration-300">
            <summary class="bg-inherit px-5 py-3 text-lg cursor-pointer">The first requently asked question</summary>
            <div class="bg-white px-5 py-3 border border-gray-300 text-sm font-light">
                <p>Praesent posuere nisi vel diam congue varius. Praesent lacus nulla, congue nec rhoncus nec,
                    suscipit et enim. Donec ultrices nec arcu et pharetra.</p>
            </div>
        </details> */}

                                        <div dangerouslySetInnerHTML={ { __html: data.body } }></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default CourseDetails;