import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const Category = ( { wrapperClass, styleClass, items } ) => {
    return (
        <>
            <div className={ `row ${ wrapperClass || 'g-5 mt--25' }` }>
                { items.map( ( data , i ) => (
                    <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        className={ `col-lg-3 col-md-6 col-sm-6 col-12 ${ styleClass ? styleClass : '' }` }
                        animateOnce={ true }
                        key={ data.id }
                    >
                        <div className="service-card service-card-1 radius-small">
                            <a href={ `/formation/${ data.id }` }>
                            <div className="inner">
                                <div className="thumbnail">
                                        <img src={`/images/formations/${ data.image }`} alt="Category Thumb" />
                                </div>
                                <div className="content">
                                    <h6 className="title">{ data.title }</h6>
                                </div>
                            </div>
                            </a>
                        </div>
                    </ScrollAnimation>
                ) ) }
            </div>   
        </>
    )
}

export default Category;