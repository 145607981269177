import React from 'react';
import SEO from '../../common/SEO';
import Header from '../../common/header/Header';
import Banner from '../../components/banner/Banner';
import About from '../../components/about/About';
import Categories from '../../components/home/Categories';
import LogosCertif from '../../components/logocertif/LogosCertif.js';
import Footer from '../../common/footer/Footer';
import Details from '../../components/Details';
import Formules from '../../components/Formules/Formules';

const Home = () => {
    return (
        <>
            <SEO title="Accueil" />

            <Header styles="header-transparent header-style-2" />

            <Banner />

            <About />

            <Categories />

            <Details />

            {/* <Formules /> */}

            <LogosCertif />

            {/* <CallToActionTwo /> */}

            <Footer />   
        </>
    )
}

export default Home;