import React from 'react';
import FormModal from '../modal/FormModal';

const Banner = () => {
    return (
        <div className="slider-area banner-style-1 bg-image height-940 d-flex align-items-center">
            <div className="container eduvibe-animated-shape">
                <div className="row g-4 row--40 align-items-center">
                    <div className="order-2 order-xl-1 col-lg-12 col-xl-12">
                        <div className="banner-left-content">
                            <div className="inner">
                                <div className="content">
                                    <h1 className="title">La nouvelle vision de la formation</h1>
                                    <p className="description color-white">Financez votre formation grâce au CPF<br></br>
                                    Suivez-la sur mobile ou sur ordinateur<br></br>
                                    Recevez votre diplôme, toutes nos formations sont certifiantes !</p>
                                    <FormModal></FormModal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Banner;