import React from 'react';
import Header from './header/Header';
import Footer from './footer/Footer';
import '../test.css'

const Layout = ( { children, isCourse } ) => {
    return (
        <>
            {/* <Header styles="header-style-2" /> */}
            <Header styles={ "header-style-2 " + (isCourse === "true" ? " bg-image-nous" : "")} />
            <main className="main-wrapper">
                { children }
                <Footer />
            </main>
        </>
    )
}
export default Layout;