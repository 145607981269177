const Details = () => {
    return (
        <>
        <div className="edu-service-area service-wrapper-1 edu-section-gap bg-primary-color">
        <div className="container eduvibe-animated-shape">
            <div className="row g-5">

                <div className="text-center col-lg-4 col-md-6 col-sm-6 col-12">
                <img className="p-3" height="250" width="250" src="/images/house.png" alt="Logo" />
                        <h4>A domicile</h4>
                        <p className="text-white">Des formations 100% en ligne à domicile, à suivre à votre propre rythme.
10 minutes par jour ?... ou 2 heures chaque week-end ? C'est vous qui choisissez où et quand suivre la formation.</p>   
                </div>

                <div className="text-center col-lg-4 col-md-6 col-sm-6 col-12">
                <img className="p-3" height="250" width="250" src="/images/money.png" alt="Logo" />
                        <h4>Éligible au CPF</h4>
                        <p className="text-white">Envie de booster votre CV, de changer de carrière ou de faire reconnaître vos compétences et savoir-faire ? Optez pour la certification !

Nos titres certifiés  (éligibles de fait au CPF - Compte Personnel de Formation), nos formations certifiantes (éligibles au CPF) et notre attestation QUALIOPI valident vos compétences professionnelles et attestent votre maîtrise d’un métier, d’une fonction ou d’une activité.  </p>
                </div>

                <div className="text-center col-lg-4 col-md-6 col-sm-6 col-12">
                <img className="p-3 " height="250" width="250" src="/images/diploma1.png" alt="Logo" />
                        <h4>Certifiant</h4>
                        <p className="text-white">Les formations certifiantes proposées par des marques expertes et inscrites sur France Compétence vous permettent de valider vos compétences professionnelles et d’attester votre maîtrise d’un métier, d’une fonction ou d’une activité en vue d’une évolution professionnelle ou d’une reconversion.

Découvrez nos différentes certifications pour booster votre CV et développer votre employabilité !</p>
                </div>
            </div>
        </div>
        </div>
        </>
    )
}

export default Details;