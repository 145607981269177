import SectionTitle from "../sectionTitle/SectionTitle";

const LogosCertif = () => {
    return (
        <div className="edu-service-area service-wrapper-1 edu-section-gap">
            <div className="container">
                
                <SectionTitle       
                    classes = "text-center"     
                    slogan = ""     
                    title = "Nos certifications"        
                />      
            </div>

            <div className="container text-center">
                {/* <img className="p-3" height="250" width="250" src="/images/logo_moncompteformation_rvb.png" alt="Logos Certif" /> */}
                {/* <img className="p-3" height="250" width="250" src="/images/logo_datadock.png" alt="Logos Certif" /> */}
                <img className="p-3" height="250" width="250" src="/images/Qualiopi-PNG-Envoll-1.png" alt="Logos Certif" />
            </div>

        </div>
    )
}

export default LogosCertif;