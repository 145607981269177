import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import CourseDetails from './pages/detailspages/CourseDetails';
import Home from './pages/homepages/Home';
import Error from './pages/innerpages/Error';

// Import Css Here 
import './assets/scss/style.scss';

function App() {
    return (
        <Router>
            <ScrollToTop>
                <Routes>
                    <Route exact path='/' element={<Home/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/formation/:id'}`} element={<CourseDetails/>}/>
                    <Route path='*' element={<Error/>}/>
                </Routes>
            </ScrollToTop>
        </Router>
    );
}

export default App;
