import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';

const About = () => {
    return (
        <>
            <div className="edu-workshop-area eduvibe-home-three-video workshop-style-1 edu-section-gap bg-image bg-color-primary">
                <div className="container eduvibe-animated-shape">
                    <div className="row gy-lg-0 gy-5 row--60 align-items-center">
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="workshop-inner">
                                <SectionTitle
                                    classes = "text-white"
                                    slogan = "Créez votre propre site web !"
                                    title = "Apprenez à utiliser Wordpress"
                                />

                                <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={ true }
                                >
                                    <p className="description">Grâce à WordPress vous pourrez créer un site internet vous-même simplement et rapidement.</p>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="read-more-btn"
                                    animateOnce={ true }
                                >
                                    <Link className="edu-btn btn-white" to="/formation/32e70b5f-dcd2-4738-9309-2c568924c620">Voir la formation<i className="icon-arrow-right-line-right"></i></Link>
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="thumbnail video-popup-wrapper">
                                <img className="radius-small w-100" src={`${process.env.PUBLIC_URL}/images/formations/wordpress_1.png`} alt="Video PopUp Thumb" />
                            </div>
                        </div>
                    </div>

                    <div className="row gy-lg-0 gy-5 row--60 mt-5 align-items-center">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="workshop-inner">
                                <SectionTitle
                                    classes = "text-white"
                                    slogan = "Créez votre entreprise dès aujourd'hui"
                                    title = "Création d'entreprise"
                                />

                                <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={ true }
                                >
                                    <p className="description">Grâce à cette formation vous pourrez créer votre entreprise afin de devenir votre propre chef et d'organiser votre travail comme bon vous semble.</p>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="read-more-btn"
                                    animateOnce={ true }
                                >
                                    <Link className="edu-btn btn-white" to="/formation/c33345fc-76a1-4781-9717-f3b6bbe2fbe3">Voir la formation<i className="icon-arrow-right-line-right"></i></Link>
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail video-popup-wrapper">
                                <img className="radius-small w-100" src={`${process.env.PUBLIC_URL}./images/formations/creationentreprise_1.png`} alt="Video PopUp Thumb" />
                            </div>
                        </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="/images/shapes/shape-09-01.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-04-05.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="/images/shapes/shape-13-02.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About;