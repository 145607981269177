import React from 'react';
import { Link } from 'react-router-dom';
import FormationsData from '../../data/formations/FormationsData.js'; 
import '../../test.css'

const Nav = ({ textColor }) => {
    return (
        <ul className="mainmenu">
            { FormationsData.map((category) => (
                <li key={category.title} className="has-droupdown">
                    <Link to="" className={'text-big ' + textColor}>{ category.title }</Link>
                    <ul className="submenu">
                        { category.items.map((course) => (
                            <li key={course.id}><Link to={ "/formation/" + course.id }>{ course.title }</Link></li>
                        ) ) }
                    </ul>
                </li>
            ) ) }
            <li className="has-droupdown">
                <Link to="" className={'text-big ' + textColor}>Outils </Link>
                <ul className='submenu'>
                    <li><a href="/CGV_VISIOFORM.pdf" download>CGV</a></li>
                    <li><a href="/VISIOFORM_Reglement_interieur-2.pdf" download>Règlement intérieur</a></li>
                </ul>
            </li>
        </ul>
    )
}
export default Nav;
